import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../tools/services/auth.service';

import * as Format from '../tools/services/format.service';
import * as BrandCvt from '../tools/services/brandConverter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-myinfo',
  templateUrl: './myinfo.component.html',
  styleUrls: ['./myinfo.component.css']
})
export class MyInfoComponent implements OnInit {
  private format = Format;
  private brandCvt = BrandCvt;
  private myInfoObj = {
    adminName: "",
    adminTel: "",
    adminRoleId: -1,
    adminRoleName: "",
    adminEmail: "",
    adminCreateDateTime: "",
    adminBrandType: "",
    adminCompanySn: "",
    adminCompanyCode: "",
    adminCompanyName: "",
    adminCompanyBossName: "",
    adminCompanyTel: "",
    adminCompanyAddress: "",
  }
  
  private isUpdate: boolean;
  constructor(private router: Router, private rest: RestService, private auth: AuthService, private translate: TranslateService) {
    this.myInfoObj.adminName = this.auth.admin.name;
    this.myInfoObj.adminTel = this.auth.admin.tel;
    this.myInfoObj.adminRoleId = this.auth.admin.role_id;

    this.myInfoObj.adminBrandType = this.auth.admin.brand_type;
    this.myInfoObj.adminCompanySn = this.auth.admin.admin_company.sn;
    this.myInfoObj.adminCompanyCode = this.auth.admin.admin_company.code;
    this.myInfoObj.adminCompanyName = this.auth.admin.admin_company.name;
    this.myInfoObj.adminCompanyBossName = this.auth.admin.admin_company.boss_name;
    this.myInfoObj.adminCompanyTel = this.auth.admin.admin_company.tel;
    this.myInfoObj.adminCompanyAddress = this.auth.admin.admin_company.address;
  }

  ngOnInit() {
    // my info 가져오기
     this.rest.getAdminById(this.auth.admin.id).subscribe(
      res => {
        if (res["success"]) {
          const resultAdmin = res["user"];
          this.myInfoObj.adminName = resultAdmin.name;
          this.myInfoObj.adminTel = resultAdmin.tel;
          this.myInfoObj.adminRoleId = resultAdmin.role_id;
          this.myInfoObj.adminEmail = resultAdmin.email;
          this.myInfoObj.adminCreateDateTime = resultAdmin.create_datetime;

          this.myInfoObj.adminBrandType = resultAdmin.brand_type;
          this.myInfoObj.adminCompanySn = resultAdmin.admin_company.sn;
          this.myInfoObj.adminCompanyCode = resultAdmin.admin_company.code;
          this.myInfoObj.adminCompanyName = resultAdmin.admin_company.name;
          this.myInfoObj.adminCompanyBossName = resultAdmin.admin_company.boss_name;
          this.myInfoObj.adminCompanyTel = resultAdmin.admin_company.tel;
          this.myInfoObj.adminCompanyAddress = resultAdmin.admin_company.address;
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.GET.ADMIN_INFO"));
        }
      });
  }

  updateAdminPw(adminPwFrom: NgForm) {
    const adminObj = adminPwFrom.value;
    
    if (this.format.isEmpty(adminObj.cur_pw) || this.format.isEmpty(adminObj.new_pw)
    ) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PASSWORD"));
      return
    }

    const result = confirm(this.translate.instant("ALERT.CONFIRM.UPDATE.PASSWORD"));
    if (!result) {
      return;
    }

    this.rest.checkAdminPassword(
      {
        id: this.auth.admin.id,
        password: adminObj.cur_pw,
      }
    ).subscribe(
      res => {
        if (res["success"]) {
          if (res["is_exist"]) {
            this.rest.updateAdminPassword({
              id: this.auth.admin.id,
              password: adminObj.new_pw,
            }).subscribe(
              res2 => {
                if (res2["success"]) {
                  alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
                  adminPwFrom.reset();
                }
                else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.COMMON"));
              });
          }else {
            alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.INVALID_CUR_PW"));
          }
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.COMMON"));
      }
    )

   
  }
}
