import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class EnsureAuthCompany  {
  constructor(private router: Router, private auth: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isCurrentUser) {
      // 내 회사도 아니고, 스페셜원 사람도 거부할 것
      const companySn = route.params["company_sn"];
      if (this.auth.admin.company_sn == companySn) {
        return true;
      }else if(this.auth.isSponeCompany()) {
        return true;
      }else {
        return false;
      }
    }
    else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}