import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Format from "../tools/services/format.service";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-company-list",
  templateUrl: "./company-list.component.html",
  styleUrls: ["./company-list.component.css"],
})
export class CompanyListComponent implements OnInit {
  private searchText: string;

  private format = Format;
  private companyTypes: any;
  private companyCodes: any;
  private companies: any;
  private companiesFilteredCount = { count: 0 };

  private viewCount: number;

  constructor(
    private router: Router,
    private rest: RestService,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    this.viewCount = 10;
  }

  ngOnInit() {
    this.getCompanyList();
    this.getCompanyTypeList();
    this.getCompanyCodeList();
  }
  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
    }
  }
  setSearchText(val) {
    this.searchText = val;
    this.viewCount = 10;
  }
  getCompanyTypeList() {
    this.rest.getCompanyTypeList().subscribe((res) => {
      if (res["success"]) {
        this.companyTypes = res["company_type_list"];
        console.log("this.companyTypes = ", this.companyTypes);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }
  getCompanyCodeList() {
    this.rest.getCompanyCodeList().subscribe((res) => {
      if (res["success"]) {
        this.companyCodes = res["company_code_list"];
        console.log("this.companyCodes = ", this.companyCodes);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }
  getCompanyList() {
    this.rest.getCompanyList().subscribe((res) => {
      if (res["success"]) {
        this.companies = res["company_list"];
        console.log("this.companies = ", this.companies);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }
  createcompany(companyForm: NgForm) {
    const companyObj = {
      company_type_seq: companyForm.value.type,
      code: companyForm.value.code,
      sn: companyForm.value.sn,
      name: companyForm.value.name,
      boss_name: "", // companyForm.value.boss_name,
      address: "", //companyForm.value.address,
      tel: "", //companyForm.value.tel,
    };

    let typeName;
    this.companyTypes.forEach((element) => {
      if (element.seq == companyObj.company_type_seq) typeName = element.name;
    });

    console.log("companyObj = ", companyObj);
    if (this.format.isEmpty(companyObj.company_type_seq)) {
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.COMPANY_TYPE"));
      return;
    } else if (this.format.isEmpty(companyObj.sn)) {
      alert(
        this.translate.instant(
          "ALERT.VALIDATE.INPUT.COMPANY_REGISTRATION_NUMBER"
        )
      );
      return;
    } else if (this.format.isEmpty(companyObj.name)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.COMPANY_NAME"));
      return;
    }
    // } else if (this.format.isEmpty(companyObj.boss_name)) {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.CEO_NAME"));
    //   return;
    // } else if (this.format.isEmpty(companyObj.address)) {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.ADDRESS"));
    //   return;
    // } else if (this.format.isEmpty(companyObj.tel)) {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.PHONE_NUMBER"));
    //   return;
    // }

    // if (!this.format.regEexCheck(companyObj.tel, "number")) {
    //   alert(
    //     this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_PHONE_NUMBER")
    //   );
    //   return;
    // }

    if (
      confirm(
        this.translate.instant("ALERT.CONFIRM.ADD.COMPANY") +
          "\n\n" +
          this.translate.instant("COMPANY") +
          " " +
          this.translate.instant("TYPE") +
          ": " +
          typeName +
          "\n" +
          this.translate.instant("COMPANY_REGISTRATION_NUMBER") +
          ": " +
          companyObj.sn +
          "\n" +
          this.translate.instant("COMPANY") +
          " " +
          this.translate.instant("NAME") +
          ": " +
          companyObj.name +
          "\n" +
          this.translate.instant("CEO") +
          " " +
          this.translate.instant("NAME") +
          ": " +
          companyObj.boss_name +
          "\n" +
          this.translate.instant("ADDRESS") +
          ": " +
          companyObj.address +
          "\n" +
          this.translate.instant("PHONE_NUMBER") +
          ": " +
          companyObj.tel
      )
    ) {
      this.rest.createCompany(companyObj).subscribe((res) => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          companyForm.reset();
          this.getCompanyList();
        } else if (res.message.original.code == "ER_DUP_ENTRY") {
          alert(
            this.translate.instant(
              "ALERT.ERROR.DUPLICATE.COMPANY_REGISTRATION_NUMBER"
            )
          );
          return;
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
        }
      });
    }
  }
  addViewCount() {
    this.format.addViewCount(this.viewCount, 10, (view_count) => {
      this.viewCount = view_count;
      this.getCompanyList();

      if (this.viewCount >= this.companiesFilteredCount.count) {
        let viewmore = document.getElementById("viewmore");
        viewmore.style.display = "none";
      }
    });
  }
}
