import { Component, OnInit } from '@angular/core';
import { RestService } from '../../app/tools/services/rest.service';
import * as Format from '../tools/services/format.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fwvers',
  templateUrl: './fwvers.component.html',
  styleUrls: ['./fwvers.component.css']
})
export class FwversComponent implements OnInit {

  private searchText: string;
  private orderColumn: string;
  private isDesc: boolean;

  private pageObj: any;

  private fwvers: any[];
  private fwversFilteredCount = { count: 0 };
  private format = Format;

  private initFileSrcBase64;
  private fwFileSrcBase64;
  private initFileSize;
  private fwFileSize;

  constructor(private rest: RestService, private translate: TranslateService) {
    this.orderColumn = 'created';
    this.isDesc = true;

    this.pageObj = {
      view_count: 30,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1
    }
  }
  ngOnInit() {
    this.getFwVers();
  }

  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
      this.renewalPage();
    }
  }
  setSearchText(val) {
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
    this.searchText = val;
  }
  setOrder(column) {
    if (this.orderColumn == column) this.isDesc = !this.isDesc;
    else {
      this.isDesc = false;
      this.orderColumn = column
    };
  }

  renewalPage() {
    this.pageSettingPageData();
    this.pageBtnActivateYn();
    this.pageMoveBlockBtnDisabledYn();
  }
  pageSettingPageData() {
    this.pageObj.totalpage = Math.ceil(this.fwversFilteredCount.count / this.pageObj.view_count);
    if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
    this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

    if (this.pageObj.currentblock > this.pageObj.totalblock) this.pageObj.currentblock = this.pageObj.totalblock;
    if (this.pageObj.currentpage > this.pageObj.totalpage) {
      this.pageObj.currentpage = this.pageObj.totalpage;
      this.pageMovePage(this.pageObj.totalpage % 5);
    }
  }
  pageGetPage() {
    this.renewalPage();

    if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
    let value = Math.floor(this.pageObj.totalpage - ((this.pageObj.currentblock - 1) * 5));
    if (value == 0) value = 5;

    return value;
  }
  pageMoveBlock(type) {
    if (type == 1) {
      this.pageObj.currentblock--;
    }
    else if (type == 2) {
      this.pageObj.currentblock++;
    }
    this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + 1;
    this.renewalPage();
  }
  pageMovePage(pagenum) {
    this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + pagenum;
    this.renewalPage();
  }
  pageBtnActivateYn() {
    let currIdx;
    let pastIdx;

    let currentIndex = this.pageObj.currentpage % 5;
    if (currentIndex == 0) currentIndex = 5;
    currIdx = 'page' + currentIndex

    for (let i = 1; i <= 5; i++) {
      pastIdx = 'page' + i;
      document.getElementById(pastIdx).classList.remove('btn-dark');
      document.getElementById(pastIdx).classList.add('btn-outline-dark');
    }
    document.getElementById(currIdx).classList.add('btn-dark');
    document.getElementById(currIdx).classList.remove('btn-outline-dark');
  }
  pageMoveBlockBtnDisabledYn() {
    if (this.pageObj.currentblock >= this.pageObj.totalblock) { document.getElementById("page_next").classList.add('disabled'); }
    else { document.getElementById("page_next").classList.remove('disabled'); }

    if (this.pageObj.currentblock == 1) { document.getElementById("page_prev").classList.add('disabled'); }
    else { document.getElementById("page_prev").classList.remove('disabled'); }
  }
  getFwVers() {
    this.rest.getFwVers().subscribe(
      res => {
        if (res["success"]) {
          this.fwvers = res['fwvers'];
          this.fwvers.forEach( e => {
            e.dfu_ver = parseFloat(e.dfu_ver);
          })
          console.log("this.fwvers = ", this.fwvers);
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
        }
      });
  }
  flushFileData(type) {
    if (type == 'init') {
      this.initFileSize = '';
      this.initFileSrcBase64 = undefined;
    } else if (type == 'fw') {
      this.fwFileSize = '';
      this.fwFileSrcBase64 = undefined;
    }
  }

  setFileData(type, event) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      if (event.target.files[0].size >= (46 * 1024)) {
        alert(this.translate.instant("ALERT.ERROR.FILE.SIZE_46KB"));

        if (type == 'init') document.getElementById("fwVerAddFormInitFile")['value'] = '';
        else if (type == 'fw') document.getElementById("fwVerAddFormFwFile")['value'] = '';

        return;
      } else {
        let res;

        if (type == 'init') {
          reader.onload = () => {
            this.initFileSize = event.target.files[0].size;
            // this.initFileSrcBase64 = reader.result;
            res = reader.result;
            this.initFileSrcBase64 = res.split(",")[1];
          }
        } else if (type == 'fw') {
          reader.onload = () => {
            this.fwFileSize = event.target.files[0].size;
            // this.fwFileSrcBase64 = reader.result;
            res = reader.result;
            this.fwFileSrcBase64 = res.split(",")[1];
          }
        }
      }
    }
  }

  postFwVer(fwForm: NgForm) {
    const fwObj = fwForm.value;

    if (this.format.isEmpty(fwObj.fwver)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.VERSION"));
      return;
    } else if (!this.format.regEexCheck(fwObj.fwver, 'float')) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_VERSION"));
      return
    } else if (this.format.isEmpty(this.initFileSrcBase64)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.INIT_FILE"));
      return;
    } else if (this.format.isEmpty(this.fwFileSrcBase64)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.FW_FILE"));
      return;
    }

    if (confirm(this.translate.instant("ALERT.CONFIRM.ADD.FW") + "\n\n" + 
    this.translate.instant("VERSION") + ": " + fwObj.fwver)) {
      this.rest.postFwVer(fwObj.fwver, this.initFileSrcBase64, this.initFileSize, this.fwFileSrcBase64, this.fwFileSize).subscribe(
        res => {
          if (res["success"]) {
            alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
            this.orderColumn = 'created';
            this.isDesc = true;
            fwForm.reset();
            document.getElementById("fwVerAddFormInitFile")['value'] = '';
            document.getElementById("fwVerAddFormFwFile")['value'] = '';
            this.getFwVers();
          } else {
            alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
          }
        });
    }
  }
  ToggleAddForm() {
    var addformdiv = document.getElementById("fwVerAddForm");
    if (addformdiv.style.display == 'none') addformdiv.style.display = 'block';
    else addformdiv.style.display = 'none';
  }
}
