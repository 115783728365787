import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from '../tools/services/rest.service';
import { AuthService } from '../tools/services/auth.service';
import * as Format from '../tools/services/format.service';
import * as BrandCvt from '../tools/services/brandConverter.service';
import * as Lsm from '../tools/services/localstorageManager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-adminlist',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {

  private searchText: string;
  private searchTextLog: string;
  private orderColumn: string;
  private orderColumnLog: string;
  private isDesc: boolean;
  private isDescLog: boolean;

  private pageObj: any;
  private pageObjLog: any;

  private adminUserLogs: any[]
  private adminUserLogsCount: number;

  private roles: any;
  private adminCreateRoles: any[] = new Array();
  private admins: any[];
  private adminsFilteredCount = { count: 0 };
  private adminsCount: any = {};
  private actionLogs: any;
  private format = Format;
  private brandCvt = BrandCvt;
  private lsm = Lsm;

  private adminType: string;
  private authPermitObj: any = {};

  private hidePassword = true;


  constructor(private router: Router, private rest: RestService, private auth: AuthService, private translate: TranslateService) {
    this.authPermitObj = {
      companySn: "",
      roleId: -1,
      isUpdate: false
    };
    this.adminsCount = {
      basic: 0,
      agency: 0,
      branch: 0,
      supervisor: 0
    }
    this.orderColumn = 'create_datetime';
    this.isDesc = true;
    this.orderColumnLog = 'create_datetime';
    this.isDescLog = true;
    this.searchText = '';
    this.searchTextLog = '';
    this.adminType = '_';
    this.pageObj = {
      view_count: 15,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1
    }
    this.pageObjLog = {
      view_count: 15,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1
    }
  }
  ngOnInit() {
    this.permissionRestrictions();

    this.getRoleList();
    this.getAdminList(true);
    this.getActionLogs();

    this.getAdminUserLogListCount();
    this.getAdminUserLogList();
  }
  enter(event: KeyboardEvent, type, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(type, searchvalue);
      if (type == 'main') {
        this.renewalPage(type);
      } else if (type == 'log') {
        this.getAdminUserLogListCount();
        this.getAdminUserLogList();
      }
    }
  }
  setPageToFirst(type) {
    if (type == 'main') {
      this.pageObj.currentblock = 1;
      this.pageObj.currentpage = 1;
    } else if (type == 'log') {
      this.pageObjLog.currentblock = 1;
      this.pageObjLog.currentpage = 1;
    }
  }
  setSearchText(type, val) {
    if (type == 'main') this.searchText = val;
    else if (type == 'log') this.searchTextLog = val;
  }

  renewalPage(type) {
    this.pageSettingPageData(type);
    this.pageBtnActivateYn(type);
    this.pageMoveBlockBtnDisabledYn(type);
  }
  howmanyEntries(type, amount): string {
    if (amount == 0) {
      return '0';
    } else {
      return ((this.pageObjLog.currentpage - 1) * this.pageObjLog.view_count) + 1 + ' to ' + this.getMaxEntries('log') + ' of ' + amount;
    }
  }
  permissionRestrictions() {
    this.authPermitObj.companySn = this.auth.admin.company_sn;
    if (this.auth.admin.role_id < 8) {
      this.authPermitObj.isUpdate = false;
    }
    else {
      this.authPermitObj.isUpdate = true
    }
  }
  createAdmin(adminForm: NgForm) {
    const adminObj = adminForm.value;
    adminObj.tel = adminForm.value.id;
    adminObj.brand_type = this.lsm.getCurrentUserBrandByBrandValue();
    //
    // 현재 이메일을 사용하지 않고있어서,
    // 빈값으로 처리
    adminObj.email = '';
    if (this.format.isEmpty(adminObj.company_sn)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.COMPANY_REGISTRATION_NUMBER"));
      return;
    } else if (this.format.isEmpty(adminObj.role_id)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.AUTHORITY"));
      return;
    } else if (this.format.isEmpty(adminObj.id)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.ID_PHONE_NUMBER"));
      return;
    } else if (!this.format.regEexCheck(adminObj.id, 'mobilePhonNum')) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_ID_PHONE_NUMBER"));
      return;
    } else if (this.format.isEmpty(adminObj.password)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PASSWORD"));
      return;
    } else if (this.format.isEmpty(adminObj.name)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.ADMIN_NAME"));
      return;
    }
    // } else if (this.format.isEmpty(adminObj.email)) {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.ADMIN_EMAIL"));
    //   return;
    // } else if (!this.format.regEexCheck(adminObj.email, 'email')) {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_ADMIN_EMAIL"));
    //   return;
    // }

    //
    // 이미 위에서 번호 확인을 했기 때문에
    // 전화번호 형식은 문제가 없음
    //
    // 전화번호의 숫자만 추출해서 저장하도록 조정
    adminObj.id = adminObj.id.replace(/[^\d]/g, ''); // 비숫자 문자 제거 

    const result = confirm(this.translate.instant("ALERT.CONFIRM.ADD.ADMIN")
      + "\n\n" + this.translate.instant("COMPANY_REGISTRATION_NUMBER") +  ": " + adminObj.company_sn
      + "\n" + this.translate.instant("AUTHORITY") + " " + this.translate.instant("TYPE") +  ": " + this.format.formatToRole(adminObj.role_id, this.translate)
      + "\n" + this.translate.instant("BRAND") +  ": " + this.brandCvt.brandStrToKor(adminObj.brand_type, this.translate)
      + "\n" + this.translate.instant("ID_PHONE_NUMBER") +  ": " + this.format.setDataFormat(adminObj.id, 'tel')
      + "\n" + this.translate.instant("ADMIN_NAME") +  ": " + adminObj.name
      // + "\n" + this.translate.instant("ADMIN_EMAIL") +  ": " + adminObj.email
    );
    if (!result) {
      return;
    }

    console.log("adminForm = ", adminObj);
    this.rest.createAdmin(adminObj).subscribe(
      res => {
        console.log("res =", res);
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.ADD.ADMIN"));
          adminForm.reset(
            {
              company_sn: adminObj.company_sn
            }
          );
          const element = <HTMLSelectElement>document.getElementById('role_id');
          element.value = '';
          this.setOrder('main', 'create_datetime');
          this.isDesc = true;
          this.setPageToFirst('main');
          this.pageMovePage('main', 1);
          this.getAdminList(true);
        } else {
          if (res["code"] === 3) alert(this.translate.instant("NOT_EXIST_COMPANY_REGISTRATION_NUMBER"));
          else if (res["code"] === 100) alert(this.translate.instant("ALERT.ERROR.DUPLICATE.ADMIN"));
          else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.ADMIN"));
        }
      },
      error => {
        alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.ADMIN"));
        console.log(error);
      },
      () => console.log("createAdmin() complate!!")
    );
  }
  deleteAdmin(adminId, adminRole) {
    if (this.auth.admin.role_id < 8) {
      if (this.auth.admin.role_id <= adminRole) {
        alert(this.translate.instant("NO_AUTH"));
        return
      }
    }
    const result = confirm(this.translate.instant("ALERT.CONFIRM.DELETE.ADMIN"));
    if (!result) return;
    this.rest.deleteAdmin(adminId).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.DELETE.ADMIN"));
          this.setPageToFirst('main');
          this.pageMovePage('main', 1);
          this.getAdminList(true);
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.ADMIN"));
      });
  }
  setOrder(type, column) {
    if (type == 'main') {
      if (this.orderColumn == column) this.isDesc = !this.isDesc;
      else {
        this.isDesc = false;
        this.orderColumn = column
      };
    } else if (type == 'log') {
      if (this.orderColumnLog == column) this.isDescLog = !this.isDescLog;
      else {
        this.isDescLog = false;
        this.orderColumnLog = column
      };
      this.getAdminUserLogList();
    }
  }
  convertLogQueryParams(type, param) {
    if (type == 'main') {
      if (param == 'sort') {
        if (this.isDesc == true) return 'desc';
        else return 'asc';
      } else if (param == 'page') {
        return this.pageObj.view_count * (this.pageObj.currentpage - 1);
      } else if (param == 'search') {
        if (this.searchText == undefined || this.searchText == '' || this.searchText == null) return '_';
        else return this.searchText;
      }
    } else if (type == 'log') {
      if (param == 'sort') {
        if (this.isDescLog == true) return 'desc';
        else return 'asc';
      } else if (param == 'page') {
        return this.pageObjLog.view_count * (this.pageObjLog.currentpage - 1);
      } else if (param == 'search') {
        if (this.searchTextLog == undefined || this.searchTextLog == '' || this.searchTextLog == null) return '_';
        else return this.searchTextLog;
      }
    }
  }
  getAdminUserLogList() {
    let _sort = this.convertLogQueryParams('log', 'sort');
    let _page = this.convertLogQueryParams('log', 'page');
    let _search = this.convertLogQueryParams('log', 'search');
    console.log(_search, this.orderColumnLog, _sort, this.pageObjLog.view_count, _page);
    this.rest.getAdminUserLogList(_search, this.orderColumnLog, _sort, this.pageObjLog.view_count, _page).subscribe(
      res => {
        if (res["success"]) {
          this.adminUserLogs = res["admin_user_action_log_list"]
          console.log("this.adminUserLogs = ", this.adminUserLogs);
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.LOG"));
      });
  }
  getAdminUserLogListCount() {
    let _search = this.convertLogQueryParams('log', 'search');
    this.rest.getAdminUserLogListCount(_search).subscribe(
      res => {
        if (res["success"]) {
          console.log("관리자 액션 로그 총 수 = ", this.adminUserLogsCount);
          this.adminUserLogsCount = res['admin_user_action_log_list_count'][0].totalItem;
        } 
        else alert(this.translate.instant("FAILED_TO_GET_CAR_MAKER"));
      });
  }

  getMaxEntries(type) {
    let maxEntries = this.pageObjLog.view_count * this.pageObjLog.currentpage;
    if (this.adminUserLogsCount <= maxEntries) return this.adminUserLogsCount;

    return maxEntries;
  }

  pageSettingPageData(type) {
    if (type == 'main') {
      this.pageObj.totalpage = Math.ceil(this.adminsFilteredCount.count / this.pageObj.view_count);
      if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
      this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

      if (this.pageObj.currentblock > this.pageObj.totalblock) this.pageObj.currentblock = this.pageObj.totalblock;
      if (this.pageObj.currentpage > this.pageObj.totalpage) {
        this.pageObj.currentpage = this.pageObj.totalpage;
        this.pageMovePage(type, this.pageObj.totalpage % 5);
      }
    } else if (type == 'log') {
      this.pageObjLog.totalpage = Math.ceil(this.adminUserLogsCount / this.pageObjLog.view_count);
      if (this.pageObjLog.totalpage == 0) this.pageObjLog.totalpage = 1;
      this.pageObjLog.totalblock = Math.ceil(this.pageObjLog.totalpage / 5);

      if (this.pageObjLog.currentblock > this.pageObjLog.totalblock) this.pageObjLog.currentblock = this.pageObjLog.totalblock;
      if (this.pageObjLog.currentpage > this.pageObjLog.totalpage) {
        this.pageObjLog.currentpage = this.pageObjLog.totalpage;
        this.pageMovePage(type, this.pageObjLog.totalpage % 5);
      }
    }
  }
  pageGetPage(type) {
    this.renewalPage(type);

    let value;
    if (type == 'main') {
      if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
      else value = Math.floor(this.pageObj.totalpage - ((this.pageObj.currentblock - 1) * 5));
    } else if (type == 'log') {
      if (this.pageObjLog.currentblock == 1) return this.pageObjLog.totalpage;
      else value = Math.floor(this.pageObjLog.totalpage - ((this.pageObjLog.currentblock - 1) * 5));
    }
    if (value == 0) value = 5;
    return value;
  }
  pageMoveBlock(type, rl) {
    let pastIndex;
    if (type == 'main') {
      pastIndex = this.pageObj.currentpage - ((this.pageObj.currentblock - 1) * 5);

      if (rl == 1) this.pageObj.currentblock--;
      else if (rl == 2) this.pageObj.currentblock++;

      this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + 1;
      this.getAdminUserLogList();
    } else if (type == 'log') {
      pastIndex = this.pageObjLog.currentpage - ((this.pageObjLog.currentblock - 1) * 5);

      if (rl == 1) this.pageObjLog.currentblock--;
      else if (rl == 2) this.pageObjLog.currentblock++;

      this.pageObjLog.currentpage = ((this.pageObjLog.currentblock - 1) * 5) + 1;
    }
    this.renewalPage(type);
  }
  pageMovePage(type, pagenum) {
    if (type == 'main') {
      this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + pagenum;
    } else if (type == 'log') {
      this.pageObjLog.currentpage = ((this.pageObjLog.currentblock - 1) * 5) + pagenum;
      this.getAdminUserLogList();
    }
    this.renewalPage(type);
  }

  pageBtnActivateYn(type) {
    let pastIdx;
    let currIdx;
    if (type == 'main') {
      let currentIndex = this.pageObj.currentpage % 5;
      if (currentIndex == 0) currentIndex = 5;
      currIdx = 'page' + currentIndex;

      for (let i = 1; i <= 5; i++) {
        pastIdx = "page" + i;
        document.getElementById(pastIdx).classList.remove('btn-dark');
        document.getElementById(pastIdx).classList.add('btn-outline-dark');
      }
      document.getElementById(currIdx).classList.add('btn-dark');
      document.getElementById(currIdx).classList.remove('btn-outline-dark');
    } else if (type == 'log') {
      let currentIndex = this.pageObjLog.currentpage % 5;
      if (currentIndex == 0) currentIndex = 5;
      currIdx = 'pageLog' + currentIndex;

      for (let i = 1; i <= 5; i++) {
        pastIdx = "pageLog" + i;
        document.getElementById(pastIdx).classList.remove('active');
      }
      document.getElementById(currIdx).classList.add('active');
    }
  }
  pageMoveBlockBtnDisabledYn(type) {
    if (type == 'main') {
      if (this.pageObj.currentblock >= this.pageObj.totalblock) { document.getElementById("page_next").classList.add('disabled'); }
      else { document.getElementById("page_next").classList.remove('disabled'); }

      if (this.pageObj.currentblock == 1) { document.getElementById("page_prev").classList.add('disabled'); }
      else { document.getElementById("page_prev").classList.remove('disabled'); }
    } else if (type == 'log') {
      if (this.pageObjLog.currentblock >= this.pageObjLog.totalblock) { document.getElementById("pageLog_next").classList.add('disabled'); }
      else { document.getElementById("pageLog_next").classList.remove('disabled'); }

      if (this.pageObjLog.currentblock == 1) { document.getElementById("pageLog_prev").classList.add('disabled'); }
      else { document.getElementById("pageLog_prev").classList.remove('disabled'); }
    }
  }

  getRoleList() {
    this.rest.getRoleList().subscribe(
      res => {
        if (res["success"]) {
          if (this.auth.admin.role_id < 8) {
            res['role_list'].forEach(element => {
              if (element.id < this.auth.admin.role_id)
                this.adminCreateRoles.push(element);
            });
          } else {
            this.adminCreateRoles = res['role_list'];
          }
          this.roles = res['role_list'];
          console.log("this.roles = ", this.roles);
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.AUTH_LIST"));
      });
  }
  getAdminList(isUpdate) {
    this.rest.getAdminList(this.adminType).subscribe(
      res => {
        this.admins = res['user_list']['rows'];
        if (isUpdate) {
          if (res["success"]) {
            this.adminsCount.basic = 0;
            this.adminsCount.agency = 0;
            this.adminsCount.branch = 0;
            this.adminsCount.supervisor = 0;
            this.adminsCount.total = res['user_list']['count']
            this.admins.forEach(element => {
              if (element.role_id == 1) {
                this.adminsCount.basic++;
              } else if (element.role_id == 2) {
                this.adminsCount.agency++;
              }
              else if (element.role_id == 4) {
                this.adminsCount.branch++;
              }
              else if (element.role_id == 8) {
                this.adminsCount.supervisor++;
              }
            });
            console.log("this.adminCount = ", this.adminsCount);
            console.log("this.admins = ", this.admins);
          }
          else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.ADMIN_LIST"));
        } 
      });
  }
  getActionLogs() {
    this.rest.getActionLogs().subscribe(
      res => {
        if (res["success"]) {
          console.log(res);
          this.actionLogs = res['logs'];
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.ADMIN_ACTION_LOG"));
      });
  }
}
