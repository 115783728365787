import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Format from "../tools/services/format.service";
import * as BrandCvt from '../tools/services/brandConverter.service';
import * as Lsm from '../tools/services/localstorageManager.service';
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-countryuserlist",
  templateUrl: "./country-user-list.component.html",
  styleUrls: ["./country-user-list.component.css"],
})
export class CountryUserListComponent implements OnInit {
  private format = Format;
  private brandCvt = BrandCvt;
  private lsm = Lsm;

  private searchText: string;
  private orderColumn: string;
  private isDesc: boolean;

  private pageObj: any;

  private carsCount: number;
  private startDate: any;
  private endDate: any;
  private generalUsers: any[];
  private generalUsersCount: number;

  private userAmount: any = {};

  private userType: string;

  private hidePassword = true;
  private countryCode: string;

  constructor( private rest: RestService, private router: Router, private auth: AuthService, private translate: TranslateService ) {
    this.carsCount = 0;
    this.orderColumn = "created";
    this.isDesc = true;
    this.searchText = "";
    this.userType = "_";
    this.countryCode = auth.getCompanyCode();

    this.pageObj = {
      view_count: 10,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1,
    };
    this.userAmount = {
      total: -1,
      owner: -1,
      waiter: -1,
    };
    const eDate = new Date();
    const sDate = new Date( eDate.getFullYear(), eDate.getMonth(), eDate.getDate() - 30 );
    this.startDate = sDate.toISOString().split("T")[0];
    this.endDate = eDate.toISOString().split("T")[0];
  }

  ngOnInit() {
    this.getGeneralUserAmount();
    this.getGeneralUserData();
  }

  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
      this.getGeneralUserData();
      this.getGeneralUserListCount();
    }
  }
  setSearchText(val) {
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
    this.searchText = val;
  }
  renewalPage() {
    this.pageSettingPageData();
    this.pageBtnActivateYn();
    this.pageMoveBlockBtnDisabledYn();
  }
  getGeneralUserData() {
    this.getGeneralUserListCount();
    this.getGeneralUserList();
  }
  setOrder(column) {
    if (this.orderColumn == column) this.isDesc = !this.isDesc;
    else {
      this.isDesc = false;
      this.orderColumn = column;
    }
    this.getGeneralUserList();
  }
  convertLogQueryParams(param) {
    if (param == "sort") {
      if (this.isDesc == true) return "desc";
      else return "asc";
    } else if (param == "page") {
      return this.pageObj.view_count * (this.pageObj.currentpage - 1);
    } else if (param == "search") {
      if ( this.searchText == undefined || this.searchText == "" || this.searchText == null )
        return "_";

      if (this.searchText.match(/\d+/g)) {
        const filteredValue = this.searchText.match(/\d+/g).join('');
        if ( filteredValue == undefined || filteredValue == "" || filteredValue == null )
          return "_";

        return filteredValue;
      }

      return this.searchText;
    }
  }
  getGeneralUserList() {
    const _sort = this.convertLogQueryParams("sort");
    const _page = this.convertLogQueryParams("page");
    const _search = this.convertLogQueryParams("search");
    
    this.rest.getGeneralUserListByCountryCode( this.userType, _search, this.orderColumn, _sort, this.pageObj.view_count, _page , this.countryCode).subscribe((res) => {
        if (res["success"]) {
          this.generalUsers = res["user_list"];
          this.getGeneralUserAmount();
        } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  }
  getGeneralUserListCount() {
    const _search = this.convertLogQueryParams("search");
    this.rest.getGeneralUserListCountByCountryCode(this.userType, _search, this.countryCode).subscribe((res) => {
        if (res["success"]) {
          this.generalUsersCount = res["user_list_count"][0].totalItem;
        } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  }
  getGeneralUserAmount() {
    this.rest.getGeneralUserListCountByCountryCode("_", "_", this.countryCode).subscribe( (res) => (this.userAmount.total = res["user_list_count"][0].totalItem) );
    this.rest.getGeneralUserListCountByCountryCode("1", "_", this.countryCode).subscribe( (res) => (this.userAmount.owner = res["user_list_count"][0].totalItem) );
    this.rest.getGeneralUserListCountByCountryCode("2", "_", this.countryCode).subscribe( (res) => (this.userAmount.waiter = res["user_list_count"][0].totalItem));
  }
  pageSettingPageData() {
    this.pageObj.totalpage = Math.ceil( this.generalUsersCount / this.pageObj.view_count );

    if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
    this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

    if (this.pageObj.currentblock > this.pageObj.totalblock)
      this.pageObj.currentblock = this.pageObj.totalblock;
    if (this.pageObj.currentpage > this.pageObj.totalpage) {
      this.pageMovePage(this.pageObj.totalpage % 5);
    }
  }
  pageGetPage() {
    this.renewalPage();

    if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
    let value = this.pageObj.totalpage - (this.pageObj.currentblock - 1) * 5;
    if (value == 0) value = 5;

    return value;
  }
  pageMoveBlock(rl) {
    if (rl == 1) this.pageObj.currentblock--;
    else if (rl == 2) this.pageObj.currentblock++;

    this.pageObj.currentpage = (this.pageObj.currentblock - 1) * 5 + 1;
    this.getGeneralUserList();
  }
  pageMovePage(pagenum) {
    this.pageObj.currentpage = (this.pageObj.currentblock - 1) * 5 + pagenum;
    this.getGeneralUserList();
  }
  setPageToFirst() {
    this.pageObj.currentpage = 1;
    this.pageObj.currentblock = 1;
  }
  pageBtnActivateYn() {
    let currId;
    let pastId;

    let currentIndex = this.pageObj.currentpage % 5;
    if (currentIndex == 0) currentIndex = 5;
    currId = "page" + currentIndex;

    for (let i = 1; i <= 5; i++) {
      pastId = "page" + i;
      document.getElementById(pastId).classList.remove('btn-dark');
      document.getElementById(pastId).classList.add('btn-outline-dark');
    }
    document.getElementById(currId).classList.add('btn-dark');
    document.getElementById(currId).classList.remove('btn-outline-dark');
  }
  pageMoveBlockBtnDisabledYn() {
    if ( this.pageObj.currentblock >= this.pageObj.totalblock ) { document.getElementById("page_next").classList.add("disabled"); } 
    else { document.getElementById("page_next").classList.remove("disabled"); }

    if (this.pageObj.currentblock == 1) { document.getElementById("page_prev").classList.add("disabled"); } 
    else { document.getElementById("page_prev").classList.remove("disabled"); }
  }
}
