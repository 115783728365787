import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class EnsureAuthAgency  {
  constructor(private router: Router, private auth: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isCurrentUser) {
      if (this.auth.admin.role_id >= 2) {
        return true;
      }
      // TODO: 권한이 없는 페이지에 접근할때에 아무 반응이 없도록 현재는 구현함.
      // this.router.navigateByUrl('/err', { state: { code: 401 } });
      return false;
    }
    else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}