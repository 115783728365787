import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class EnsureAuthCompanySpone  {
  constructor(private router: Router, private auth: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isCurrentUser) {
      if (this.auth.isSponeCompany()) {
        return true;
      }
  
      return false;
    }
    else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}