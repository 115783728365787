import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {

  transform( items: any[], property: any, descending?: boolean ): any {
    if (property) {
      items = items.sort( (a: any, b: any) => {
        if(a[property]===null) a[property] = '';
        if(b[property]===null) b[property] = '';

        if ( typeof a[property] == 'number' ) {
          if ( a[property] < b[property] ) return -1;
          else if ( a[property] > b[property] ) return 1;
          else return 0;
        }
        else {
          return a[property].toString().localeCompare(b[property].toString())
        }
      });

      if (descending) return items.reverse();
    }
    
    return items;
  }
}
