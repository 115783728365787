import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-err',
  templateUrl: './err.component.html',
  styleUrls: ['./err.component.css']
})
export class ErrComponent implements OnInit {

  // TODO: 정상 동작하는지 지속적으로 확인 필요함. 
  
  private CODE: number = 404;
  constructor(private router: Router) {
    if(this.router.getCurrentNavigation().extras.state)
    this.CODE = this.router.getCurrentNavigation().extras.state.code;
   }

  ngOnInit() {
  }

}
