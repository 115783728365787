import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../tools/services/auth.service';
import { BaseMenuComponent } from '../basemenu'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidenavigation',
  templateUrl: './sidenavigation.component.html',
  styleUrls: ['./sidenavigation.component.css']
})
export class SidenavigationComponent extends BaseMenuComponent{
  constructor( auth:AuthService,  router : Router, translate: TranslateService) {
    super(auth, router,  translate);
    
  }
}
