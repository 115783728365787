import { Component, OnInit } from "@angular/core";
import { AuthService } from "../tools/services/auth.service";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({})
export class BaseMenuComponent implements OnInit {
  public naviSubscription;
  public adminName: any;
  public adminTel: any;
  public adminRoleId: any;
  public adminBrandType: any;
  public adminCompanySn: any;
  public adminCompanyCode: any;

  constructor(
    public auth: AuthService,
    protected router: Router,
    private translate: TranslateService
  ) {
    this.adminName = -1;
    this.adminTel = -1;
    this.adminRoleId = -1;
    this.adminBrandType = null;
    this.adminCompanySn = null;
    this.adminCompanyCode = null;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.naviSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
        const currentUser = localStorage.getItem("currentUser");

        if (this.router.url == "/err") {
          document.getElementById("layoutSidenav_nav").hidden = true;
          document.getElementById("sidenavAccordion").hidden = true;
        } else if (currentUser) {
          this.adminName = this.auth.admin.name;
          this.adminTel = this.auth.admin.tel;
          this.adminRoleId = this.auth.admin.role_id;
          this.adminBrandType = this.auth.admin.brand_type;
          this.adminCompanySn = this.auth.admin.admin_company.sn;
          this.adminCompanyCode = this.auth.admin.admin_company.code;

          document.getElementById("layoutSidenav_nav").hidden = false;
          document.getElementById("sidenavAccordion").hidden = false;
        } else {
          this.auth.setLogout();
          document.getElementById("layoutSidenav_nav").hidden = true;
          document.getElementById("sidenavAccordion").hidden = true;
        }
      }
    });
  }
  ngOnInit() {}
  getRoleToString() {
    if (!this.adminRoleId) return -1;
    else if (this.adminRoleId == 2)
      return (
        "[" +
        this.translate.instant("AGENCY") +
        " " +
        this.translate.instant("ADMIN") +
        "]"
      );
    else if (this.adminRoleId == 4)
      return (
        "[" +
        this.translate.instant("DISTRIBUTER") +
        " " +
        this.translate.instant("ADMIN") +
        "]"
      );
    else if (this.adminRoleId == 8)
      return (
        "[" +
        this.translate.instant("SUPERVISOR") +
        " " +
        this.translate.instant("ADMIN") +
        "]"
      );
  }

  ToggleSide() {
    document.body.classList.toggle("sidenav-toggled");
  }
  ToggleBottom() {
    document.getElementById("loginInfo").classList.toggle("show");
  }

  ngOnDestroy(): void {
    if (this.naviSubscription) {
      this.naviSubscription.unsubscribe();
    }
  }
  navigateComponent(url: string) {
    this.router.navigateByUrl(url);
  }
}
