import { Component, OnInit } from '@angular/core';
import { RestService } from '../../tools/services/rest.service';
import * as Format from '../../tools/services/format.service'
import { NgForm } from '@angular/forms';
import * as Lsm from '../../tools/services/localstorageManager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  private searchText: string;
  private orderColumn: string;
  private isDesc: boolean;

  private pageObj: any;
  
  private faqs: any[];
  private faqsFilteredCount = { count : 0 };
  private format = Format;
  private lsm = Lsm;

  constructor( private rest: RestService, private translate: TranslateService ) { 
  }

  ngOnInit() {
    this.orderColumn = 'created';
    this.isDesc = true;
    this.getFaqs();

    this.pageObj = {
      view_count: 10,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1
    }
  }
  enter(event: KeyboardEvent, searchvalue){
    if (event.keyCode == 13){
      this.setSearchText(searchvalue);
      this.renewalPage();
    }
  }
  setSearchText( val ){
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
    this.searchText = val;
  }
  renewalPage(){
    this.pageSettingPageData();
    this.pageBtnActivateYn();
    this.pageMoveBlockBtnDisabledYn();
  }
  setOrder(column) {
    if (this.orderColumn == column) this.isDesc = !this.isDesc;
    else {
      this.isDesc = false;
      this.orderColumn = column
    };
  }
  pageSettingPageData() {
    this.pageObj.totalpage = Math.ceil(this.faqsFilteredCount.count / this.pageObj.view_count);
    if(this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
    this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

    if (this.pageObj.currentblock > this.pageObj.totalblock) this.pageObj.currentblock = this.pageObj.totalblock;
    if (this.pageObj.currentpage > this.pageObj.totalpage) {
      this.pageObj.currentpage = this.pageObj.totalpage;
      this.pageMovePage(this.pageObj.totalpage % 5);
    }
  }
  pageGetPage() {
    this.renewalPage();
    
    if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
    let value = Math.floor(this.pageObj.totalpage - ((this.pageObj.currentblock - 1) * 5));
    if (value == 0) value = 5;

    return value;
  }
  pageMoveBlock(type){
    if(type == 1){
      this.pageObj.currentblock--; 
    }
    else if(type == 2){
      this.pageObj.currentblock++;
    }
    this.pageObj.currentpage = ((this.pageObj.currentblock-1)*5)+1;
    this.renewalPage();
  }
  pageMovePage(pagenum){
    this.pageObj.currentpage = ((this.pageObj.currentblock-1)*5)+pagenum;
    this.renewalPage();
  }
  
  pageBtnActivateYn() {
    let currId;
    let pastId;
    
    let currentIndex = this.pageObj.currentpage % 5;
    if ( currentIndex == 0 ) currentIndex = 5;
    currId = 'page' + currentIndex

    for( let i = 1; i <= 5 ; i++ ) {
      pastId = 'page' + i;
      document.getElementById(pastId).classList.remove('btn-dark');
      document.getElementById(pastId).classList.add('btn-outline-dark');
    }
    document.getElementById(currId).classList.add('btn-dark');
    document.getElementById(currId).classList.remove('btn-outline-dark');
  }
  pageMoveBlockBtnDisabledYn() {
    if (this.pageObj.currentblock >= this.pageObj.totalblock) { document.getElementById("page_next").classList.add('disabled'); }
    else { document.getElementById("page_next").classList.remove('disabled'); }

    if (this.pageObj.currentblock == 1) { document.getElementById("page_prev").classList.add('disabled'); }
    else { document.getElementById("page_prev").classList.remove('disabled'); }
  }

  getFaqs(){
    this.rest.getfaqs().subscribe(
      res => {
        if (res["success"]) {
          this.faqs = res['faqs'];
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.FAQ_LIST"));
      });
  }
  createFaq(faqForm: NgForm){
    const faqObj = faqForm.value;

    if( this.format.isEmpty( faqObj.title ) ){
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.SUBJECT"));
      return;
    } else if( this.format.isEmpty( faqObj.answer ) ){
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CONTENTS"));
      return;
    }

    const faqBody = {
      title: "Q."+ faqObj.title,
      content:  faqObj.title,
      answer: "A."+ faqObj.answer,
      is_view: 1,
      brand_type: this.lsm.getCurrentUserBrandByBrandValue()
    }
    this.rest.createfaq(faqBody).subscribe(
      res => {
        console.log("res =", res);
        if(res["success"]){
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          faqForm.reset();
          this.pageObj.currentblock = 1;
          this.pageMovePage(1);
          this.setOrder('created');
          this.isDesc = true;
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
      },
      error => console.log(error),
      () => {
        this.getFaqs();
        console.log("createFaq() complate!!");
      }
    );
  }
  deleteFaq(faqSeq){
    let result = confirm(this.translate.instant("ALERT.CONFIRM.DELETE.BOARD_ITEM"));
    if(!result) return;
    this.rest.deletefaq(faqSeq).subscribe(
      res => {
        console.log("res =", res);
        console.log("res =", res);
        if(res["success"]){
          alert("삭제 완료");
          this.pageObj.currentblock = 1;
          this.pageMovePage(1);
          this.getFaqs();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.COMMON"));
      });
  }
}
