import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class _HttpClient {
  private headers;
  private dupliAlertFlag = false;
  private dupliAlertFlag400 = false;
  private dupliAlertUnknown = false;
  private isUser = false;

  constructor(private http: HttpClient, private auth: AuthService, private router: Router, private translate: TranslateService) { }

  createAuthorizationHeader() {
    const admin = JSON.parse(localStorage.getItem('currentUser'));
    
    if ( !admin ) {
      this.isUser = false;
    } else {
      this.isUser = true;
      const token = this.auth.authToken;
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': token
      });
    }
  }

  noauthget(url) {
    const res = this.http.get(url).pipe(
      tap(
        next => res,
        err => {
          this.printError(err);
          console.error("(noauth)get request error!\n", err);
        }
      )
    );
    return res;
  }
  noauthpost(url, data) {
    const res = this.http.post(url, data).pipe(
      tap(
        next => res,
        err => {
          this.printError(err);
          console.error("(noauth)post request error!\n", err);
        }
      )
    );
    return res;
  }

  get(url) {
    this.createAuthorizationHeader();
    if( !this.isUser ){
      alert(this.translate.instant("ALERT.ERROR.INVALID_ACCESS"));
      this.auth.setLogout();
      return;
    }
    
    const res = this.http.get(url, { headers: this.headers }).pipe(
      tap(
        next => res,
        err => {
          this.printError(err);
          console.error("get request error!\n", err);
        }
      )
    );
    return res;
  }

  post(url, data) {
    this.createAuthorizationHeader();
    if( !this.isUser ){
      alert(this.translate.instant("ALERT.ERROR.INVALID_ACCESS"));
      this.auth.setLogout();
      return;
    }
    
    const res = this.http.post(url, data, { headers: this.headers }).pipe(
      tap(
        next => res,
        err => {
          this.printError(err);
          console.error("post request error!\n", err);
        }
      )
    );
    return res;
  }

  put(url, data) {
    this.createAuthorizationHeader();
    if( !this.isUser ){
      alert(this.translate.instant("ALERT.ERROR.INVALID_ACCESS"));
      this.auth.setLogout();
      return;
    }
    
    const res = this.http.put(url, data, { headers: this.headers }).pipe(
      tap(
        next => res,
        err => {
          this.printError(err);
          console.error("put request error!\n", err);
        }
      )
    );
    return res;
  }

  delete( url ) {
    this.createAuthorizationHeader();
    if( !this.isUser ){
      alert(this.translate.instant("ALERT.ERROR.INVALID_ACCESS"));
      this.auth.setLogout();
      return;
    }
    
    const res = this.http.delete(url, { headers: this.headers }).pipe(
      tap(
        next => res,
        err => {
          this.printError(err);
          console.error("delete request error!\n", err);
        }
      )
    );
    return res;
  }

  printError( err ) {
    if ( err.status == 401 ) {
      if ( !this.dupliAlertFlag400 ) {
        alert(this.translate.instant("ALERT.ERROR.HTTP.401"));
        this.dupliAlertFlag400 = true;
        this.router.navigateByUrl('/err', { state: { code: 401 } });
      }
    } else if ( err.status == 404 ) {
      if ( !this.dupliAlertFlag400 ) {
        alert(this.translate.instant("ALERT.ERROR.HTTP.404"));
        this.dupliAlertFlag400 = true;
        this.router.navigateByUrl('/err', { state: { code: 404 } });
      }
    } else if ( err.status == 440 ) {
      if ( !this.dupliAlertFlag400 ) {
        alert(this.translate.instant("ALERT.ERROR.HTTP.440"));
        this.dupliAlertFlag400 = true;
      }
      this.auth.setLogout();
    } else if( err.statusText == 'Unknown Error') {
      if ( !this.dupliAlertUnknown ) {
        alert(this.translate.instant("ALERT.ERROR.HTTP.UNKNOWN"));
        this.dupliAlertUnknown = true;
      }
    } else {
      if ( !this.dupliAlertFlag ) {
        alert(this.translate.instant("ALERT.ERROR.HTTP.SERVER"));
        this.dupliAlertFlag = true;
      }
      this.router.navigateByUrl('/err', { state: { code: 500 } });
    }
  }
}