import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";

import { RestService } from "./tools/services/rest.service";
import { AuthService } from "./tools/services/auth.service";
import { EnsureAuthenticated } from "./tools/services/ensure-authenticated.service";
import { EnsureAuthAgency } from "./tools/services/ensure-auth-agency.service";
import { EnsureAuthBranch } from "./tools/services/ensure-auth-branch.service";
import { EnsureAuthSuper } from "./tools/services/ensure-auth-super.service";
import { EnsureBrandKeyplus } from "./tools/services/ensure-brand-keyplus.service";
import { EnsureAuthCompanySponeMando } from "./tools/services/ensure-auth-company-spone-mando.service";
import { EnsureAuthCompany } from "./tools/services/ensure-auth-company.service";
import { EnsureAuthCompanySpone } from "./tools/services/ensure-auth-company-spone.service";
import { LoginRedirect } from "./tools/services/login-redirect.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { _HttpClient } from "./tools/modules/httpclient";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { UserListComponent } from "./user/user-list.component";
import { UserDetailComponent } from "./user/user-detail.component";
import { UserDetailCarComponent } from "./user/user-detail-car.component";
import { CountryUserListComponent } from "./user/country-user-list.component";
import { NoticeComponent } from "./board/notice/notice.component";
import { NoticeDetailComponent } from "./board/notice/notice-detail.component";
import { FaqComponent } from "./board/faq/faq.component";
import { CarsharesComponent } from "./carshares/carshares.component";
import { AppversComponent } from "./appvers/appvers.component";
import { FwversComponent } from "./fwvers/fwvers.component";
import { CarmakerListComponent } from "./carmaker/carmaker-list.component";
import { SmsComponent } from "./sms/sms.component";
import { SidenavigationComponent } from "./menu/sidenavigation/sidenavigation.component";
import { TopmenuComponent } from "./menu/topmenu/topmenu.component";
import { AdminDetailComponent } from "./admin/admin-detail.component";
import { AdminListComponent } from "./admin/admin-list.component";
import { CarmakerDetailComponent } from "./carmaker/carmaker-detail.component";
import { ErrComponent } from "./err/err.component";
import { FilterPipe } from "./tools/pipes/filter.pipe";
import { OrderByPipe } from "./tools/pipes/order-by.pipe";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarsearchComponent } from "./carsearch/carsearch.component";
import { KeyworkComponent } from "./keywork/keywork.component";
import { CompanyListComponent } from "./company/company-list.component";
import { CompanyDetailComponent } from "./company/company-detail.component";
import { SaleComponent } from "./sale/sale.component";
import { AsComponent } from "./as/as.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CompanyCodeListComponent } from "./company/company-code-list.component";
import { DeviceListComponent } from "./device/device-list.component";
import { DeviceDetailComponent } from "./device/device-detail.component";
import { MyInfoComponent } from "./myinfo/myinfo.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserListComponent,
    UserDetailComponent,
    UserDetailCarComponent,
    CountryUserListComponent,
    NoticeComponent,
    NoticeDetailComponent,
    FaqComponent,
    CarsharesComponent,
    AppversComponent,
    FwversComponent,
    CarmakerListComponent,
    AdminListComponent,
    AdminDetailComponent,
    SmsComponent,
    SidenavigationComponent,
    TopmenuComponent,

    CarmakerDetailComponent,
    ErrComponent,
    FilterPipe,
    OrderByPipe,
    CarsearchComponent,
    KeyworkComponent,
    CompanyDetailComponent,
    CompanyListComponent,
    CompanyCodeListComponent,
    DeviceListComponent,
    DeviceDetailComponent,
    SaleComponent,
    AsComponent,
    MyInfoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    _HttpClient,
    RestService,
    AuthService,
    EnsureAuthenticated,
    EnsureAuthAgency,
    EnsureAuthBranch,
    EnsureAuthSuper,
    EnsureBrandKeyplus,
    EnsureAuthCompanySponeMando,
    EnsureAuthCompany,
    EnsureAuthCompanySpone,
    LoginRedirect,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  // TODO : 지원하는 언어에 맞게 json 파일을 아래 경로에 추가 해줘야 함.
  // 예) 한국어 : ko.json, 영어 : en.json
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
