import { Component, OnInit } from "@angular/core";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Lsm from "../tools/services/localstorageManager.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as Format from "../tools/services/format.service";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-device-detail",
  templateUrl: "./device-detail.component.html",
})
export class DeviceDetailComponent implements OnInit {
  private format = Format;
  private device: any;
  private deviceSeq: any;

  constructor(
    private rest: RestService,
    private router: Router,
    private actRouter: ActivatedRoute,
    private auth: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.deviceSeq = this.actRouter.snapshot.params["device_seq"];
    this.getDevice();
  }

  getDevice() {
    // TODO : 향후 상세 정보를 조회할 때 구현 해야 함.
    // this.rest.getDevice(this.deviceSeq).subscribe((res) => {
    //   if (res["success"]) {
    //     this.device = res["device"];
    //     console.log("this.device = ", this.device);
    //   } else {
    //     alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    //   }
    // });
  }
}
