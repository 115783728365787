import * as brandCvt from './brandConverter.service';

const KEY_CURRENT_USER = "currentUser";
const KEY_TOKEN = "token";
const KEY_LANG = "lang";
const KEY_LOGIN_ID = "loginId";
const KEY_LOGIN_PW = "loginPw";
const KEY_LOGIN_BRAND_TYPE = "loignBrandType";

function getObjValueByKey(key){
  const obj = localStorage.getItem(key);

  if(obj == null) {
    return null;
  }
  else {
    return JSON.parse(obj);
  }
}

function getStrValueByKey(key){
  return localStorage.getItem(key);
}

function setObjValue(key, obj){
  const objStr = JSON.stringify(obj);
  localStorage.setItem(key, objStr);
}

function setStrValue(key, str){
  localStorage.setItem(key, str);
}

function removeValue(key){
  localStorage.removeItem(key);
}

export function getValueCurrentUser(){
  return getObjValueByKey(KEY_CURRENT_USER);
}

export function setValueCurrentUser(obj){
  setObjValue(KEY_CURRENT_USER, obj);
}

export function removeValueCurrentUser(){
  return removeValue(KEY_CURRENT_USER);
}

export function getValueToken(){
  return getStrValueByKey(KEY_TOKEN);
}

export function setValueToken(str){
  setStrValue(KEY_TOKEN, str);
}

export function removeValueToken(){
  return removeValue(KEY_TOKEN);
}

export function getCurrentUserBrandByBrandValue(){  
  const value = getCurrentUserBrandType();

  return brandCvt.brandValueToStr(value);
}

export function getCurrentUserBrandType(){
  const currentUser = getObjValueByKey(KEY_CURRENT_USER);
  if(currentUser == null){
    return false;
  } else {
    return currentUser.brand_type;
  }
}

export function getValueLang() {
  return getStrValueByKey(KEY_LANG);
}

export function setValueLang(str){
  setStrValue(KEY_LANG, str);
}

export function removeValueLang(){
  return removeValue(KEY_LANG);
}

export function getValueLoginId() {
  return getStrValueByKey(KEY_LOGIN_ID);
}

export function setValueLoginId(str){
  setStrValue(KEY_LOGIN_ID, str);
}

export function removeValueLoginId(){
  return removeValue(KEY_LOGIN_ID);
}

export function getValueLoginPw() {
  return getStrValueByKey(KEY_LOGIN_PW);
}

export function setValueLoginPw(str){
  setStrValue(KEY_LOGIN_PW, str);
}

export function removeValueLoginPw(){
  return removeValue(KEY_LOGIN_PW);
}

export function getValueLoginBrandType() {
  return getStrValueByKey(KEY_LOGIN_BRAND_TYPE);
}

export function setValueLoginBrandType(str){
  setStrValue(KEY_LOGIN_BRAND_TYPE, str);
}

export function removeValueLoginBrandType(){
  return removeValue(KEY_LOGIN_BRAND_TYPE);
}