import { Pipe, PipeTransform } from '@angular/core';
import * as Format from '../services/format.service';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  format = Format;

  transform(items: any[], searchText: String, filterCount, ...columnArr: any[]): any {
    if ( ! items ) {
      filterCount.count = 0;
      return [];
    }else if ( ! searchText || ! columnArr || searchText == '_') {
      filterCount.count = items.length;
      return items;
    }

    let filteredItems = items.filter(row => {
      let columns = columnArr[0];
      let nofilterYn = 0;
      
      for( let col of columns ){
        if( !this.format.isEmpty(row[col]) ){
          if( row[col].toString().toLowerCase().includes(searchText.toLowerCase() ) ) nofilterYn++;
        }
      }
      return nofilterYn;
    });

    filterCount.count = filteredItems.length;
    return filteredItems;
  }
}