import { Config } from "../../config/config";
import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { _HttpClient } from "../modules/httpclient";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import * as lsm from "./localstorageManager.service";

@Injectable()
export class RestService {
  private url: string = Config.REST_API_SERVER;

  constructor(private http: _HttpClient) {
  }

  // 문자 서비스 부분
  postSms(body) {
    return this.http.post(this.url + "/admin/sms", body).pipe(
      tap(
        (next) => console.log("postSms() start"),
        (err) => console.log("postSms() error", err),
        () => console.log("postSms() complete")
      ),
      catchError(this.handleError)
    );
  }
  getPopbillChargeUrl() {
    return this.http.get(this.url + "/admin/sms/chargeUrl").pipe(
      tap(
        (next) => console.log("getPopbillChargeUrl() start"),
        (err) => console.log("getPopbillChargeUrl() error", err),
        () => console.log("getPopbillChargeUrl() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 차량 검색 부분
  getCarSearchList() {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http.get(this.url + "/admin/carsearch/brand/" + brand).pipe(
      tap(
        (next) => console.log("getCarSearchList() start"),
        (error) => console.log("getCarSearchList() error", error),
        () => console.log("getCarSearchList() complete")
      ),
      catchError(this.handleError)
    );
  }

  // 차제조사 부분
  getReferCarMakers() {
    return this.http.get(this.url + "/admin/refercar").pipe(
      tap(
        (next) => console.log("getReferCarMakers() start"),
        (error) => console.log("getReferCarMakers() error", error),
        () => console.log("getReferCarMakers() complete")
      ),
      catchError(this.handleError)
    );
  }
  postReferCarMaker(carmaker, logo) {
    const body = {
      car_maker: carmaker,
      logo_image: logo,
    };
    return this.http.post(this.url + "/admin/refercar/", body).pipe(
      tap(
        (next) => console.log("postRefercarMaker() start"),
        (err) => console.log("postReferCarMaker() error", err),
        () => console.log("postReferCarMaker() complete")
      ),
      catchError(this.handleError)
    );
  }
  putReferCarMaker(seq, carmaker, logo) {
    const body = {
      seq: seq,
      car_maker: carmaker,
      logo_image: logo,
    };
    return this.http.put(this.url + "/admin/refercar/", body).pipe(
      tap(
        (next) => console.log("putReferCarMaker() start"),
        (err) => console.log("putReferCarMaker() error", err),
        () => console.log("putReferCarMaker() complete")
      ),
      catchError(this.handleError)
    );
  }
  deleteReferCarMaker(maker_seq) {
    return this.http.delete(this.url + "/admin/refercar/" + maker_seq).pipe(
      tap(
        (next) => console.log("deleteReferCarMaker() start"),
        (error) => console.log("deleteReferCarMaker() error", error),
        () => console.log("deleteReferCarMaker() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 차종 부분
  getReferCarMakerBySeq(seq) {
    return this.http.get(this.url + "/admin/refercar/" + seq).pipe(
      tap(
        (next) => console.log("getReferCarMakerBySeq() start"),
        (error) => console.log("getReferCarMakerBySeq() error", error),
        () => console.log("getReferCarMakerBySeq() complete")
      ),
      catchError(this.handleError)
    );
  }
  getReferCarMakerTypes(carMakerSeq) {
    return this.http.get(this.url + "/admin/refercar/type/" + carMakerSeq).pipe(
      tap(
        (next) => console.log("getReferCarMakerTypes() start"),
        (error) => console.log("getReferCarMakerTypes() error", error),
        () => console.log("getReferCarMakerTypes() complete")
      ),
      catchError(this.handleError)
    );
  }
  postReferCarType(carmaker, cartype) {
    const body = {
      car_maker_seq: carmaker,
      car_type: cartype,
    };
    console.log(body);
    return this.http.post(this.url + "/admin/refercar/type/", body).pipe(
      tap(
        (next) => console.log("postReferCarType() start"),
        (error) => console.log("postReferCarType() error", error),
        () => console.log("postReferCarType() complete")
      ),
      catchError(this.handleError)
    );
  }
  deleteReferCarType(car_seq) {
    return this.http.delete(this.url + "/admin/refercar/type/" + car_seq).pipe(
      tap(
        (next) => console.log("deleteReferCarType() start"),
        (error) => console.log("deleteReferCarType() error", error),
        () => console.log("deleteReferCarType() complete")
      ),
      catchError(this.handleError)
    );
  }

  // 앱버전 부분
  getAppVers() {
    return this.http.get(this.url + "/admin/appver").pipe(
      tap(
        (next) => console.log("getAppVers() start"),
        (error) => console.log("getAppVers() error ", error),
        () => console.log("getAppVers() complete")
      ),
      catchError(this.handleError)
    );
  }
  updateAppVer(type, version) {
    const body = {
      version_type: type,
      version_app: version,
    };
    console.log(body);
    return this.http.post(this.url + "/admin/appver", body).pipe(
      tap(
        (next) => console.log("updateAppVer() start"),
        (err) => console.log("updateAppVer() error", err),
        () => console.log("updateAppVer() complete")
      ),
      catchError(this.handleError)
    );
  }

  // F/W버전 부분
  getFwVers() {
    return this.http.get(this.url + "/admin/fwver").pipe(
      tap(
        (next) => console.log("getFwVers() start"),
        (error) => console.log("getFwVers() error", error),
        () => console.log("getFwVers() complete")
      ),
      catchError(this.handleError)
    );
  }
  postFwVer(fwver, initFileByBase64, initFileSize, fwFileByBase64, fwFileSize) {
    const body = {
      dfu_ver: fwver,
      dfu_init: initFileByBase64,
      dfu_init_size: initFileSize,
      dfu_file: fwFileByBase64,
      dfu_file_size: fwFileSize,
    };
    return this.http.post(this.url + "/admin/fwver", body).pipe(
      tap(
        (next) => console.log("postFwVer() start"),
        (err) => console.log("postFwVer() error", err),
        () => console.log("postFwVer() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 차량공유관리 부분
  getCarShareList(type) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(this.url + "/admin/carshare/type/" + type + "/brand/" + brand)
      .pipe(
        tap(
          (next) => console.log("getCarShareList() start"),
          (error) => console.log("getCarShareList() error ", error),
          () => console.log("getCarShareList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareListByUserId(type, userId) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/carshare/type/" +
          type +
          "/userId/" +
          userId +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareListByUserId() start"),
          (error) => console.log("getCarShareListByUserId() error ", error),
          () => console.log("getCarShareListByUserId() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareLogList(searchVal, orderBy, sort, itemsPerPage, pageIndex) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/carshare/log/" +
          searchVal +
          "/" +
          orderBy +
          "/" +
          sort +
          "/" +
          itemsPerPage +
          "/" +
          pageIndex +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareLogList() start"),
          (error) => console.log("getCarShareLogList() error ", error),
          () => console.log("getCarShareLogList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareLogListByUserId(
    userId,
    type,
    searchVal,
    orderBy,
    sort,
    itemsPerPage,
    pageIndex
  ) {
    return this.http
      .get(
        this.url +
          "/admin/carshare/userlog/" +
          userId +
          "/" +
          type +
          "/" +
          searchVal +
          "/" +
          orderBy +
          "/" +
          sort +
          "/" +
          itemsPerPage +
          "/" +
          pageIndex
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareLogListByUserId() start"),
          (error) => console.log("getCarShareLogListByUserId() error ", error),
          () => console.log("getCarShareLogListByUserId() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareLogListCountByUserId(userId, type, searchVal) {
    return this.http
      .get(
        this.url +
          "/admin/carshare/userlog/count/" +
          userId +
          "/" +
          type +
          "/" +
          searchVal
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareLogListCountByUserId() start"),
          (error) =>
            console.log("getCarShareLogListCountByUserId() error ", error),
          () => console.log("getCarShareLogListCountByUserId() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareLogListByCarSeq(
    carSeq,
    type,
    searchVal,
    orderBy,
    sort,
    itemsPerPage,
    pageIndex
  ) {
    return this.http
      .get(
        this.url +
          "/admin/carshare/carlog/" +
          carSeq +
          "/" +
          type +
          "/" +
          searchVal +
          "/" +
          orderBy +
          "/" +
          sort +
          "/" +
          itemsPerPage +
          "/" +
          pageIndex
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareLogListByCarSeq() start"),
          (error) => console.log("getCarShareLogListByCarSeq() error ", error),
          () => console.log("getCarShareLogListByCarSeq() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareLogListCountByCarSeq(carSeq, type, searchVal) {
    return this.http
      .get(
        this.url +
          "/admin/carshare/carlog/count/" +
          carSeq +
          "/" +
          type +
          "/" +
          searchVal
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareLogListCountByCarSeq() start"),
          (error) =>
            console.log("getCarShareLogListCountByCarSeq() error ", error),
          () => console.log("getCarShareLogListCountByCarSeq() complete")
        ),
        catchError(this.handleError)
      );
  }
  getCarShareLogListCount(searchVal) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url + "/admin/carshare/log/count/" + searchVal + "/brand/" + brand
      )
      .pipe(
        tap(
          (next) => console.log("getCarShareLogListCount() start"),
          (error) => console.log("getCarShareLogListCount() error ", error),
          () => console.log("getCarShareLogListCount() complete")
        ),
        catchError(this.handleError)
      );
  }

  postCarShare(car_seq, sender_tel, receiver_tel) {
    const body = {
      car_seq: car_seq,
      sender_user_id: sender_tel,
      receiver_user_id: receiver_tel,
    };
    return this.http.post(this.url + "/admin/carshare/", body).pipe(
      tap(
        (next) => console.log("postCarShare() start"),
        (err) => console.log("postCarShare() error", err),
        () => console.log("postCarShare() complete")
      ),
      catchError(this.handleError)
    );
  }

  putCarShare(seq) {
    const body = {
      seq: seq,
      is_return: -1,
    };
    return this.http.put(this.url + "/admin/carshare", body).pipe(
      tap(
        (next) => console.log("putCarShare() start"),
        (error) => console.log("putCarShare() error ", error),
        () => console.log("putCarShare() complete")
      ),
      catchError(this.handleError)
    );
  }
  putCarPartner(seq) {
    const body = {
      seq: seq,
      is_return: -1,
    };
    return this.http.put(this.url + "/admin/carpartner", body).pipe(
      tap(
        (next) => console.log("putCarPartner() start"),
        (error) => console.log("putCarPartner() error ", error),
        () => console.log("putCarPartner() complete")
      ),
      catchError(this.handleError)
    );
  }
  postCarPartner(car_seq, sender_tel, receiver_tel) {
    const body = {
      car_seq: car_seq,
      sender_user_id: sender_tel,
      receiver_user_id: receiver_tel,
    };
    return this.http.post(this.url + "/admin/carpartner/", body).pipe(
      tap(
        (next) => console.log("postCarPartner() start"),
        (err) => console.log("postCarPartner() error", err),
        () => console.log("postCarPartner() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 관리자 부분
  getRoleList() {
    return this.http.noauthget(this.url + "/unauth/role").pipe(
      tap(
        (next) => console.log("getRoleList() start"),
        (error) => console.log("getRoleList() error ", error),
        () => console.log("getRoleList() complete")
      ),
      catchError(this.handleError)
    );
  }
  createAdmin(body) {
    return this.http.post(this.url + "/unauth/user", body).pipe(
      tap(
        (next) => console.log("createAdmin() start"),
        (error) => console.log("createAdmin() error ", error),
        () => console.log("createAdmin() complete")
      ),
      catchError(this.handleError)
    );
  }
  getAdminList(type) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(this.url + "/admin/user/type/" + type + "/brand/" + brand)
      .pipe(
        tap(
          (next) => console.log("getAdminList() start"),
          (error) => console.log("getAdminList() error ", error),
          () => console.log("getAdminList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getAdminListByCompanySn(companySn, type) {
    return this.http
      .get(this.url + "/admin/user/company/" + companySn + "/type/" + type)
      .pipe(
        tap(
          (next) => console.log("getAdminListByCompanySn() start"),
          (error) => console.log("getAdminListByCompanySn() error ", error),
          () => console.log("getAdminListByCompanySn() complete")
        ),
        catchError(this.handleError)
      );
  }
  getAdminById(adminId) {
    return this.http.get(this.url + "/admin/user/" + adminId).pipe(
      tap(
        (next) => console.log("getAdminById() start"),
        (error) => console.log("getAdminById() error ", error),
        () => console.log("getAdminById() complete")
      ),
      catchError(this.handleError)
    );
  }
  updateAdmin(body) {
    return this.http.put(this.url + "/admin/user", body).pipe(
      tap(
        (next) => console.log("updateAdmin() start"),
        (error) => console.log("updateAdmin() error ", error),
        () => console.log("updateAdmin() complete")
      ),
      catchError(this.handleError)
    );
  }
  updateAdminPassword(body) {
    return this.http.put(this.url + "/admin/user/password", body).pipe(
      tap(
        (next) => console.log("updateAdminPassword() start"),
        (error) => console.log("updateAdminPassword() error ", error),
        () => console.log("updateAdminPassword() complete")
      ),
      catchError(this.handleError)
    );
  }
  checkAdminPassword(body) {
    return this.http.post(this.url + "/admin/user/password/check", body).pipe(
      tap(
        (next) => console.log("checkAdminPassword() start"),
        (error) => console.log("checkAdminPassword() error ", error),
        () => console.log("checkAdminPassword() complete")
      ),
      catchError(this.handleError)
    );
  }
  deleteAdmin(userId) {
    return this.http.delete(this.url + "/admin/user/" + userId).pipe(
      tap(
        (next) => console.log("deleteAdmin() start"),
        (error) => console.log("deleteAdmin() error ", error),
        () => console.log("deleteAdmin() complete")
      ),
      catchError(this.handleError)
    );
  }
  getActionLogs() {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http.get(this.url + "/admin/log/action/brand/" + brand).pipe(
      tap(
        (next) => console.log("getActionLogs() start"),
        (error) => console.log("getActionLogs() error ", error),
        () => console.log("getActionLogs() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 가게(company) 부분
  getCompanyTypeList() {
    return this.http.get(this.url + "/admin/company/type").pipe(
      tap(
        (next) => console.log("getCompanyTypeList() start"),
        (error) => console.log("getCompanyTypeList() error ", error),
        () => console.log("getCompanyTypeList() complete")
      ),
      catchError(this.handleError)
    );
  }
  getCompanyList() {
    return this.http.get(this.url + "/admin/company").pipe(
      tap(
        (next) => console.log("getCompanyList() start"),
        (error) => console.log("getCompanyList() error ", error),
        () => console.log("getCompanyList() complete")
      ),
      catchError(this.handleError)
    );
  }
  getCompanyCodeList() {
    return this.http.get(this.url + "/admin/company/code").pipe(
      tap(
        (next) => console.log("getCompanyCodeList() start"),
        (error) => console.log("getCompanyCodeList() error ", error),
        () => console.log("getCompanyCodeList() complete")
      ),
      catchError(this.handleError)
    );
  }
  getCompanyBySn(companySn) {
    return this.http.get(this.url + "/admin/company/sn/" + companySn).pipe(
      tap(
        (next) => console.log("getCompanyBySn() start"),
        (error) => console.log("getCompanyBySn() error ", error),
        () => console.log("getCompanyBySn() complete")
      ),
      catchError(this.handleError)
    );
  }
  createCompany(companyBody) {
    return this.http.post(this.url + "/admin/company", companyBody).pipe(
      tap(
        (next) => console.log("createCompany() start"),
        (error) => console.log("createCompany() error ", error),
        () => console.log("createCompany() complete")
      ),
      catchError(this.handleError)
    );
  }
  createCompanyCode(companyCodeBody) {
    return this.http
      .post(this.url + "/admin/company/code", companyCodeBody)
      .pipe(
        tap(
          (next) => console.log("createCompanyCode() start"),
          (error) => console.log("createCompanyCode() error ", error),
          () => console.log("createCompanyCode() complete")
        ),
        catchError(this.handleError)
      );
  }

  updateCompany(companyBody) {
    return this.http.put(this.url + "/admin/company", companyBody).pipe(
      tap(
        (next) => console.log("updateCompany() start"),
        (error) => console.log("updateCompany() error ", error),
        () => console.log("updateCompany() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 관리자 로그 부분
  getAdminUserLogList(searchVal, orderBy, sort, itemsPerPage, pageIndex) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/user/log/" +
          searchVal +
          "/" +
          orderBy +
          "/" +
          sort +
          "/" +
          itemsPerPage +
          "/" +
          pageIndex +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getAdminUserLogList() start"),
          (error) => console.log("getAdminUserLogList() error ", error),
          () => console.log("getAdminUserLogList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getAdminUserLogListCount(searchVal) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(this.url + "/admin/user/log/count/" + searchVal + "/brand/" + brand)
      .pipe(
        tap(
          (next) => console.log("getAdminUserLogListCount() start"),
          (error) => console.log("getAdminUserLogListCount() error ", error),
          () => console.log("getAdminUserLogListCount() complete")
        ),
        catchError(this.handleError)
      );
  }
  // 일반 사용자 부분
  getGeneralUserTotalCount() {
    return this.http.get(this.url + "/admin/generaluser/totalCount").pipe(
      tap(
        (next) => console.log("getGeneralUserTotalCount() start"),
        (error) => console.log("getGeneralUserTotalCount() error ", error),
        () => console.log("getGeneralUserTotalCount() complete")
      ),
      catchError(this.handleError)
    );
  }
  getGeneralUserOwnerCount() {
    return this.http.get(this.url + "/admin/generaluser/ownerCount").pipe(
      tap(
        (next) => console.log("getGeneralUserOwnerCount() start"),
        (error) => console.log("getGeneralUserOwnerCount() error ", error),
        () => console.log("getGeneralUserOwnerCount() complete")
      ),
      catchError(this.handleError)
    );
  }
  getGeneralUserPhoneList(type) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url + "/admin/generaluser/phoneList/" + type + "/brand/" + brand
      )
      .pipe(
        tap(
          (next) => console.log("getGeneralUserPhoneList() start"),
          (error) => console.log("getGeneralUserPhoneList() error ", error),
          () => console.log("getGeneralUserPhoneList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getGeneralUserList(type, searchVal, orderBy, sort, itemsPerPage, pageIndex) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/generaluser/" +
          type +
          "/" +
          searchVal +
          "/" +
          orderBy +
          "/" +
          sort +
          "/" +
          itemsPerPage +
          "/" +
          pageIndex +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getGeneralUserList() start"),
          (error) => console.log("getGeneralUserList() error ", error),
          () => console.log("getGeneralUserList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getGeneralUserListByCountryCode(type, searchVal, orderBy, sort, itemsPerPage, pageIndex, countryCode) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/generaluser/" +
          type +
          "/" +
          searchVal +
          "/" +
          orderBy +
          "/" +
          sort +
          "/" +
          itemsPerPage +
          "/" +
          pageIndex +
          "/brand/" +
          brand +
          "/countryCode/" +
          countryCode
      )
      .pipe(
        tap(
          (next) => { },
          (error) => { },
          () => { }
        ),
        catchError(this.handleError)
      );
  }
  getGeneralUserListCount(type, searchVal) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/generaluser/count/" +
          type +
          "/" +
          searchVal +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getGeneralUserListCount() start"),
          (error) => console.log("getGeneralUserListCount() error ", error),
          () => console.log("getGeneralUserListCount() complete")
        ),
        catchError(this.handleError)
      );
  }
  getGeneralUserListCountByCountryCode(type, searchVal, countryCode) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/generaluser/count/" +
          type +
          "/" +
          searchVal +
          "/brand/" +
          brand +
          "/countryCode/" +
          countryCode
      )
      .pipe(
        tap(
          (next) => { },
          (error) => { },
          () => { }
        ),
        catchError(this.handleError)
      );
  }
  getGeneralUserById(userId) {
    return this.http.get(this.url + "/admin/generaluser/userId/" + userId).pipe(
      tap(
        (next) => console.log("getGeneralUserById() start"),
        (error) => console.log("getGeneralUserById() error ", error),
        () => console.log("getGeneralUserById() complete")
      ),
      catchError(this.handleError)
    );
  }
  getGeneralUserBySeq(userSeq) {
    return this.http
      .get(this.url + "/admin/generaluser/userSeq/" + userSeq)
      .pipe(
        tap(
          (next) => console.log("getGeneralUserBySeq() start"),
          (error) => console.log("getGeneralUserBySeq() error ", error),
          () => console.log("getGeneralUserBySeq() complete")
        ),
        catchError(this.handleError)
      );
  }
  getGeneralUserMobileSettings(userSeq) {
    return this.http
      .get(this.url + "/admin/generaluser/mobile_settings/userSeq/" + userSeq)
      .pipe(
        tap(
          (next) => console.log("getGeneralUserMobileSettings() start"),
          (error) => console.log("getGeneralUserMobileSettings() error ", error),
          () => console.log("getGeneralUserMobileSettings() complete")
        ),
        catchError(this.handleError)
      );
  }
  createGeneralUser(body) {
    return this.http.post(this.url + "/admin/generaluser/", body).pipe(
      tap(
        (next) => console.log("createGeneralUser() start"),
        (error) => console.log("createGeneralUser() error ", error),
        () => console.log("createGeneralUser() complete")
      ),
      catchError(this.handleError)
    );
  }
  updateGeneralUser(body) {
    return this.http.put(this.url + "/admin/generaluser/", body).pipe(
      tap(
        (next) => console.log("updateGeneralUser() start"),
        (error) => console.log("updateGeneralUser() error ", error),
        () => console.log("updateGeneralUser() complete")
      ),
      catchError(this.handleError)
    );
  }
  updateGeneralUserPassword(body) {
    return this.http.put(this.url + "/admin/generaluser/password", body).pipe(
      tap(
        (next) => console.log("updateGeneralUserPassword() start"),
        (error) => console.log("updateGeneralUserPassword() error ", error),
        () => console.log("updateGeneralUserPassword() complete")
      ),
      catchError(this.handleError)
    );
  }
  deleteGeneralUser(userSeq) {
    return this.http.delete(this.url + "/admin/generaluser/" + userSeq).pipe(
      tap(
        (next) => console.log("deleteGeneralUser() start"),
        (error) => console.log("deleteGeneralUser() error ", error),
        () => console.log("deleteGeneralUser() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 판매 로그 부분
  getSalesList(searchVal, itemCount) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/log/sale/total/" +
          searchVal +
          "/" +
          itemCount +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getSalesList() start"),
          (error) => console.log("getSalesList() error ", error),
          () => console.log("getSalesList() complete")
        ),
        catchError(this.handleError)
      );
  }
  // unuse
  getSaleLogListCount(searchText) {
    return this.http.get(this.url + "/admin/log/sale/total/" + searchText).pipe(
      tap(
        (next) => console.log("getSaleLogListCount() start"),
        (error) => console.log("getSaleLogListCount() error ", error),
        () => console.log("getSaleLogListCount() complete")
      ),
      catchError(this.handleError)
    );
  }
  getSalesListByUserId(userId) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url + "/admin/log/sale/customer/tel/" + userId + "/brand/" + brand
      )
      .pipe(
        tap(
          (next) => console.log("getSalesListByUserId() start"),
          (error) => console.log("getSalesListByUserId() error ", error),
          () => console.log("getSalesListByUserId() complete")
        ),
        catchError(this.handleError)
      );
  }
  createSaleLog(saleBody) {
    return this.http.post(this.url + "/admin/log/sale", saleBody).pipe(
      tap(
        (next) => console.log("createSaleLog() start"),
        (error) => console.log("createSaleLog() error ", error),
        () => console.log("createSaleLog() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 차량 부분
  getCarListByUserSeq(userSeq) {
    return this.http.get(this.url + "/admin/cars/userSeq/" + userSeq).pipe(
      tap(
        (next) => console.log("getCarListByUserSeq() start"),
        (error) => console.log("getCarListByUserSeq() error ", error),
        () => console.log("getCarListByUserSeq() complete")
      ),
      catchError(this.handleError)
    );
  }
  getAllCarListByUserSeq(userSeq) {
    return this.http.get(this.url + "/admin/cars/list/all/userSeq/" + userSeq).pipe(
      tap(
        (next) => console.log("getAllCarListByUserSeq() start"),
        (error) => console.log("getAllCarListByUserSeq() error ", error),
        () => console.log("getAllCarListByUserSeq() complete")
      ),
      catchError(this.handleError)
    );
  }
  getCarByCarSeq(carSeq) {
    return this.http.get(this.url + "/admin/cars/carSeq/" + carSeq).pipe(
      tap(
        (next) => console.log("getCarByCarSeq() start"),
        (error) => console.log("getCarByCarSeq() error ", error),
        () => console.log("getCarByCarSeq() complete")
      ),
      catchError(this.handleError)
    );
  }
  getCarCountByCreatedDate(startDate, endDate) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url +
          "/admin/cars/countByDate/" +
          startDate +
          "/" +
          endDate +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getCarCountByCreatedDate() start"),
          (error) => console.log("getCarCountByCreatedDate error ", error),
          () => console.log("getCarCountByCreatedDate() complete")
        ),
        catchError(this.handleError)
      );
  }
  updateCar(body) {
    return this.http.put(this.url + "/admin/cars", body).pipe(
      tap(
        (next) => console.log("updateCar() start"),
        (error) => console.log("updateCar() error ", error),
        () => console.log("updateCar() complete")
      ),
      catchError(this.handleError)
    );
  }
  createCar(body) {
    return this.http.post(this.url + "/admin/cars", body).pipe(
      tap(
        (next) => console.log("createCar() start"),
        (error) => console.log("createCar() error ", error),
        () => console.log("createCar() complete")
      ),
      catchError(this.handleError)
    );
  }
  deleteCar(carSeq) {
    return this.http.delete(this.url + "/admin/cars/" + carSeq).pipe(
      tap(
        (next) => console.log("deleteCar() start"),
        (error) => console.log("deleteCar() error ", error),
        () => console.log("deleteCar() complete")
      ),
      catchError(this.handleError)
    );
  }
  // as로그 부분
  getAsLogTypeList() {
    return this.http.get(this.url + "/admin/log/as/typeList").pipe(
      tap(
        (next) => console.log("getAsLogTypeList() start"),
        (error) => console.log("getAsLogTypeList() error ", error),
        () => console.log("getAsLogTypeList() complete")
      ),
      catchError(this.handleError)
    );
  }
  getAsLogList(searchVal, itemCount) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    console.log(searchVal, itemCount);

    return this.http
      .get(
        this.url +
          "/admin/log/as/total/" +
          searchVal +
          "/" +
          itemCount +
          "/brand/" +
          brand
      )
      .pipe(
        tap(
          (next) => console.log("getAsLogList() start"),
          (error) => console.log("getAsLogList() error ", error),
          () => console.log("getAsLogList() complete")
        ),
        catchError(this.handleError)
      );
  }
  getAsLogListByUserId(userId) {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http
      .get(
        this.url + "/admin/log/as/customer/tel/" + userId + "/brand/" + brand
      )
      .pipe(
        tap(
          (next) => console.log("getAsLogListByUserId() start"),
          (error) => console.log("getAsLogListByUserId() error ", error),
          () => console.log("getAsLogListByUserId() complete")
        ),
        catchError(this.handleError)
      );
  }
  createAsLog(logBody) {
    return this.http.post(this.url + "/admin/log/as", logBody).pipe(
      tap(
        (next) => console.log("createAsLog() start"),
        (error) => console.log("createAsLog() error ", error),
        () => console.log("createAsLog() complete")
      ),
      catchError(this.handleError)
    );
  }
  // board 부분
  getNotices() {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http.get(this.url + "/admin/board/notices/brand/" + brand).pipe(
      tap(
        (next) => console.log("getnotices() start"),
        (error) => console.log("getnotices() error ", error),
        () => console.log("getnotices() complete")
      ),
      catchError(this.handleError)
    );
  }
  getSupportedLanguages() {
    return this.http.get(this.url + "/unauth/supported_languages").pipe(
      tap(
        (next) => console.log("getSupportedLanguages() start"),
        (error) => console.log("getSupportedLanguages() error ", error),
        () => console.log("getSupportedLanguages() complete")
      ),
      catchError(this.handleError)
    );
  }
  getNoticeBySeq(noticeSeq) {
    return this.http.get(this.url + "/admin/board/notices/" + noticeSeq).pipe(
      tap(
        (next) => console.log("getNoticeBySeq() start"),
        (error) => console.log("getNoticeBySeq() error ", error),
        () => console.log("getnotgetNoticeBySeqices() complete")
      ),
      catchError(this.handleError)
    );
  }
  createNotice(body) {
    return this.http.post(this.url + "/admin/board/notices", body).pipe(
      tap(
        (next) => console.log("createNotice() start"),
        (error) => console.log("createNotice() error ", error),
        () => console.log("createNotice() complete")
      ),
      catchError(this.handleError)
    );
  }
  addNoticeLanguage(body) {
    return this.http.post(this.url + "/admin/board/notice/lang", body).pipe(
      tap(
        (next) => console.log("createNotice() start"),
        (error) => console.log("createNotice() error ", error),
        () => console.log("createNotice() complete")
      ),
      catchError(this.handleError)
    );
  }
  deleteNotice(noticeSeq) {
    return this.http
      .delete(this.url + "/admin/board/notices/" + noticeSeq)
      .pipe(
        tap(
          (next) => console.log("deleteNotice() start"),
          (error) => console.log("deleteNotice() error ", error),
          () => console.log("deleteNotice() complete")
        ),
        catchError(this.handleError)
      );
  }
  getfaqs() {
    const brand = lsm.getCurrentUserBrandByBrandValue();
    return this.http.get(this.url + "/admin/board/faqs/brand/" + brand).pipe(
      tap(
        (next) => console.log("getfaqs() start"),
        (error) => console.log("getfaqs() error ", error),
        () => console.log("getfaqs() complete")
      ),
      catchError(this.handleError)
    );
  }
  createfaq(body) {
    return this.http.post(this.url + "/admin/board/faqs", body).pipe(
      tap(
        (next) => console.log("createfaq() start"),
        (error) => console.log("createfaq() error ", error),
        () => console.log("createfaq() complete")
      ),
      catchError(this.handleError)
    );
  }
  deletefaq(noticefaq) {
    return this.http.delete(this.url + "/admin/board/faqs/" + noticefaq).pipe(
      tap(
        (next) => console.log("deletefaq() start"),
        (error) => console.log("deletefaq() error ", error),
        () => console.log("deletefaq() complete")
      ),
      catchError(this.handleError)
    );
  }
  // 친구 부분
  getFriendListByUserId(userId) {
    return this.http.get(this.url + "/admin/friends/userId/" + userId).pipe(
      tap(
        (next) => console.log("getFriendListByUserId() start"),
        (error) => console.log("getFriendListByUserId() error ", error),
        () => console.log("getFriendListByUserId() complete")
      ),
      catchError(this.handleError)
    );
  }
  createFriend(body) {
    return this.http.post(this.url + "/admin/friends", body).pipe(
      tap(
        (next) => console.log("createFriend() start"),
        (error) => console.log("createFriend() error ", error),
        () => console.log("createFriend() complete")
      ),
      catchError(this.handleError)
    );
  }

  // TODO : 향후 상세 정보를 조회할 때 구현 해야 함. 서버 측도 같이 구현 해야 함.
  // getDevice(deviceSeq) {
  //   return this.http.get(this.url + "/admin/devices/" + deviceSeq).pipe(
  //     tap(
  //       (next) => console.log("getDevice() start"),
  //       (error) => console.log("getDevice() error ", error),
  //       () => console.log("getDevice() complete")
  //     ),
  //     catchError(this.handleError)
  //   );
  // }

  getDeviceListByCompanyCode(companyCode) {
    return this.http
      .get(this.url + "/admin/devices/company/code/" + companyCode)
      .pipe(
        tap(
          (next) => { },
          (error) => { },
          () => { }
        ),
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("client error! :", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
