import { Component, OnInit } from "@angular/core";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Lsm from "../tools/services/localstorageManager.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as Format from "../tools/services/format.service";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-company-detail",
  templateUrl: "./company-detail.component.html",
})
export class CompanyDetailComponent implements OnInit {
  private searchText: string;
  private orderColumn: string;
  private isDesc: boolean;

  private pageObj: any;

  private format = Format;
  private companyTypes: any;
  private companyCodes: any;
  private company: any;
  private companyObj: any = {};
  private roles: any[] = new Array();
  private admins: any;
  private adminsCount = { count: 0 };
  private isUpdate: boolean;
  private adminType: string;
  private hidePassword = true;
  private lsm = Lsm;

  constructor(
    private rest: RestService,
    private router: Router,
    private actRouter: ActivatedRoute,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    this.pageObj = {
      view_count: 10,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1,
    };
    this.adminType = "_";
    this.company = {
      sn: "",
    };
  }

  ngOnInit() {
    this.company.sn = this.actRouter.snapshot.params["company_sn"];

    this.orderColumn = "name";
    this.getCompanyBySn();
    this.getCompanyTypeList();
    this.getCompanyCodeList();
    this.getRoleList(); 
    this.getAdminListByCompanySn();
    this.isBlock();
  }
  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
      this.renewalPage();
    }
  }

  setPageToFirst() {
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
  }

  setSearchText(val) {
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
    this.searchText = val;
  }
  renewalPage() {
    this.pageSettingPageData();
    this.pageBtnActivateYn();
    this.pageMoveBlockBtnDisabledYn();
  }
  setOrder(column) {
    if (this.orderColumn == column) this.isDesc = !this.isDesc;
    else {
      this.isDesc = false;
      this.orderColumn = column;
    }
  }
  pageSettingPageData() {
    this.pageObj.totalpage = Math.ceil(
      this.adminsCount.count / this.pageObj.view_count
    );
    if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
    this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

    if (this.pageObj.currentblock > this.pageObj.totalblock)
      this.pageObj.currentblock = this.pageObj.totalblock;
    if (this.pageObj.currentpage > this.pageObj.totalpage) {
      this.pageObj.currentpage = this.pageObj.totalpage;
      this.pageMovePage(this.pageObj.totalpage % 5);
    }
  }
  pageGetPage() {
    this.renewalPage();

    if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
    let value = Math.floor(
      this.pageObj.totalpage - (this.pageObj.currentblock - 1) * 5
    );
    if (value == 0) value = 5;

    return value;
  }
  pageMoveBlock(type) {
    if (type == 1) this.pageObj.currentblock--;
    else if (type == 2) this.pageObj.currentblock++;

    this.pageObj.currentpage = (this.pageObj.currentblock - 1) * 5 + 1;
    this.renewalPage();
  }
  pageMovePage(pagenum) {
    this.pageObj.currentpage = (this.pageObj.currentblock - 1) * 5 + pagenum;
    this.renewalPage();
  }
  pageBtnActivateYn() {
    let currIdx;
    let pastIdx;

    let currentIndex = this.pageObj.currentpage % 5;
    if (currentIndex == 0) currentIndex = 5;
    currIdx = "page" + currentIndex;

    for (let i = 1; i <= 5; i++) {
      pastIdx = "page" + i;
      document.getElementById(pastIdx).classList.remove("btn-dark");
      document.getElementById(pastIdx).classList.add("btn-outline-dark");
    }
    document.getElementById(currIdx).classList.add("btn-dark");
    document.getElementById(currIdx).classList.remove("btn-outline-dark");
  }
  pageMoveBlockBtnDisabledYn() {
    if (this.pageObj.currentblock >= this.pageObj.totalblock) {
      document.getElementById("page_next").classList.add("disabled");
    } else {
      document.getElementById("page_next").classList.remove("disabled");
    }

    if (this.pageObj.currentblock == 1) {
      document.getElementById("page_prev").classList.add("disabled");
    } else {
      document.getElementById("page_prev").classList.remove("disabled");
    }
  }
  isBlock() {
    if (this.auth.admin.admin_company.code != "KR") {
      this.isUpdate = false;
    } else {
      if (this.auth.admin.role_id == 8) this.isUpdate = true;
      else this.isUpdate = false;
    }
    console.log("isUpdate = " + this.isUpdate);
  }

  getCompanyTypeList() {
    this.rest.getCompanyTypeList().subscribe((res) => {
      if (res["success"]) {
        this.companyTypes = res["company_type_list"];
        console.log("this.companyTypes = ", this.companyTypes);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }

  getCompanyCodeList() {
    this.rest.getCompanyCodeList().subscribe((res) => {
      if (res["success"]) {
        this.companyCodes = res["company_code_list"];
        console.log("this.companyCodes = ", this.companyCodes);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }

  getCompanyBySn() {
    this.rest.getCompanyBySn(this.company.sn).subscribe((res) => {
      if (res["success"]) {
        this.company = res["company"];
        console.log("company = ", this.company);
        this.companyObj = Object.assign({}, this.company);
      } else {
        // alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }
  getRoleList() {
    this.rest.getRoleList().subscribe((res) => {
      if (res["success"]) {
        if (this.auth.admin.role_id < 8) {
          res["role_list"].forEach((element) => {
            if (element.id < this.auth.admin.role_id) this.roles.push(element);
          });
        } else {
          this.roles = res["role_list"];
        }
        console.log("this.roles = ", this.roles);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }
  updateCompany() {
    if (JSON.stringify(this.companyObj) === JSON.stringify(this.company))
      return;

    const result = confirm(
      this.translate.instant("ALERT.CONFIRM.UPDATE.COMMON")
    );
    if (!result) return;

    console.log("this.companyObj = ", this.companyObj);

    this.rest.updateCompany(this.companyObj).subscribe((res) => {
      if (res["success"]) {
        alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
        this.getCompanyBySn();
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.COMMON"));
    });
  }
  getAdminListByCompanySn() {
    this.rest
      .getAdminListByCompanySn(this.company.sn, this.adminType)
      .subscribe((res) => {
        if (res["success"]) {
          console.log(res);
          this.admins = res["user_list"];
          console.log(this.adminsCount);
        } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  }
  createAdmin(adminFrom: NgForm) {
    const adminObj = adminFrom.value;
    adminObj.company_sn = this.company.sn;
    adminObj.tel = adminFrom.value.id;
    adminObj.brand_type = this.lsm.getCurrentUserBrandByBrandValue();
    adminObj.email = "";
    if (adminObj.role_id == "") {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.AUTHORITY"));
      return;
    } else if (adminObj.id == "") {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.ID_PHONE_NUMBER"));
      return;
    } else if (adminObj.password == "") {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PASSWORD"));
      return;
    } else if (adminObj.name == "") {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.ADMIN_NAME"));
      return;
      //
      // 이메일 제거
    // } else if (adminObj.email == "") {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.ADMIN_EMAIL"));
    //   return;
      // ID 알파벳 허용
    } else if (adminObj.id.length < 6) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_ID_PHONE_NUMBER"));
      return;
    // } else if (!this.format.regEexCheck(adminObj.id, "number")) {
    //   alert(
    //     this.translate.instant("ALERT.VALIDATE.INPUT.PHONE_NUMBER_ONLY_NUMBERS")
    //   );
    //   return;
    // } else if (!this.format.regEexCheck(adminObj.id, "mobilePhonNum")) {
    //   alert(
    //     this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_ID_PHONE_NUMBER")
    //   );
    // return;
    } else if (adminObj.password.length < 6 || adminObj.password.length > 12) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_PASSWORD"));
      return;
    }
    // if (!this.format.regEexCheck(adminObj.email, "email")) {
    //   alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_ADMIN_EMAIL"));
    //   return;
    // }

    const result = confirm(
      this.translate.instant("ALERT.CONFIRM.ADD.ADMIN") +
        "\n" +
        this.translate.instant("AUTHORITY") +
        " " +
        this.translate.instant("TYPE") +
        ": " +
        this.format.formatToRole(adminObj.role_id, this.translate) +
        "\n" +
        this.translate.instant("ID_PHONE_NUMBER") +
        ": " +
        this.format.setDataFormat(adminObj.id, "tel") +
        "\n" +
        this.translate.instant("ADMIN") +
        " " +
        this.translate.instant("NAME") +
        ": " +
        adminObj.name +
        "\n" +
        this.translate.instant("ADMIN") +
        " " +
        this.translate.instant("EMAIL") +
        ": " +
        adminObj.email
    );
    if (!result) {
      return;
    }

    console.log("adminFrom = ", adminObj);
    this.rest.createAdmin(adminObj).subscribe((res) => {
      if (res["success"]) {
        alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
        adminFrom.reset();
        const element = <HTMLSelectElement>document.getElementById("role_id");
        element.value = "";
        this.setOrder("create_datetime");
        this.isDesc = true;
        this.setPageToFirst();
        this.pageMovePage(1);
        this.getAdminListByCompanySn();
      } else {
        if (res["code"] === 100) {
          alert(this.translate.instant("ALERT.ERROR.DUPLICATE.ADMIN"));
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
        }
      }
    });
  }

  deleteAdmin(adminId) {
    const result = confirm(
      this.translate.instant("ALERT.CONFIRM.DELETE.ADMIN")
    );
    if (!result) return;
    this.rest.deleteAdmin(adminId).subscribe((res) => {
      console.log("res =", res);
      if (res["success"]) {
        alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
        this.getAdminListByCompanySn();
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.COMMON"));
    });
  }
}
