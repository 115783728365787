import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keywork',
  templateUrl: './keywork.component.html',
  styleUrls: ['./keywork.component.css']
})
export class KeyworkComponent implements OnInit {

  private keyImgSrc: string;
  private searchText: string;
  private keyValue: string;
  private keyList = [
    { id: 1, value: "S8010", name: "팰리세이드 (2018~2020.04)" },

    { id: 2, value: "S1000", name: "산타페 TM (2018~2019)" },

    { id: 3, value: "J5210", name: "[미완료]스팅어 (2018.03~2020)" },

    { id: 4, value: "G8000", name: "그랜져 IG (2016~2019)" },
    { id: 5, value: "G8000", name: "그랜져 IG 하이브리드 (2017~2019)" },
    { id: 6, value: "G8000", name: "벨로스터 N (2018.05.18~)" },

    { id: 7, value: "G6000", name: "모닝 JA (2017~2020)" },
    { id: 8, value: "G6000", name: "더 뉴 레이 (2017~2020)" },

    { id: 9, value: "F6000", name: "올 뉴 K7 (2016~2019)" },
    { id: 10, value: "F6000", name: "올 뉴 K7 하이브리드 (2016~2019)" },
    { id: 11, value: "F6000", name: "더 뉴 쏘렌토 (2017~2019)" },
    { id: 12, value: "F6000", name: "더 K9 (2018~2019)" },

    { id: 13, value: "F2000", name: "아반떼 AD (2015~2018)" },
    { id: 14, value: "F2000", name: "더 뉴 아반떼 AD (2018~2019)" },
    { id: 15, value: "F2000", name: "LF 소나타 (2014~2017)" },
    { id: 16, value: "F2000", name: "LF 소나타 하이브리드 (2014~2017)" },
    { id: 17, value: "F2000", name: "LF 소나타 뉴 라이즈 (2017~2019)" },
    { id: 18, value: "F2000", name: "LF 소나타 뉴 라이즈 하이브리드 (2017~2019)" },

    { id: 19, value: "D9000", name: "올 뉴 쏘렌토 (2014~2017)" },
    { id: 20, value: "D9000", name: "올 뉴 스포티지 (2015~2018)" },
    { id: 21, value: "D9000", name: "올 뉴 스포티지 더 볼드 (2018~2019)" },
    { id: 22, value: "D9000", name: "니로 (2016~2019)" },

    { id: 23, value: "D4000", name: "올 뉴 카니발 (4버튼) (2018~2019)" },
    { id: 24, value: "D4000", name: "K5 2세대 (2015~2018)" },
    { id: 25, value: "D4000", name: "K5 2세대 하이브리드 (2015~2018)" },
    { id: 26, value: "D4000", name: "더 뉴 K5 2세대 (2018~2019)" },
    { id: 27, value: "D4000", name: "더 뉴 K5 2세대 하이브리드 (2018~2019)" },
    { id: 28, value: "D4000", name: "올 뉴 K5 (2015~2019)" },

    { id: 29, value: "D3110", name: "아이오닉 (2018~2020)" },
    { id: 30, value: "D3110", name: "아이오닉 EV (2019.01.14~)" },
    { id: 31, value: "D3110", name: "올 뉴 투싼(2015~2019)" },

    { id: 32, value: "D2000", name: "제네시스 G80(2016~2019)" },
    { id: 33, value: "D2000", name: "제네시스 G90(2018~2019)" },
    { id: 34, value: "D2000", name: "제네시스 EQ900(2015~2018)" },

    { id: 35, value: "A9300", name: "올 뉴 카니발 (2014~2018)" },
    { id: 36, value: "A9300", name: "더 뉴 카니발 (6버튼) (2018~2019)" },

    { id: 37, value: "3S000", name: "아반떼 MD (2010~2013)" },
    { id: 38, value: "3S000", name: "아반떼 쿠페 (2013~2015)" },
    { id: 39, value: "3S000", name: "포르테 (2010~2013)" },
    { id: 40, value: "3S000", name: "YF 소나타 (2009~2012)" },
    { id: 41, value: "3S000", name: "YF 소나타 하이브리드 (2011~2014)" },
    { id: 42, value: "3S000", name: "YF 소나타 브릴리언트 (2012~2014)" },
    { id: 43, value: "3S000", name: "K5 (2010~2013)" },
    { id: 44, value: "3S000", name: "K5 하이브리드 (2011~2015)" },
    { id: 45, value: "3S000", name: "K7 (2009.11~2012.11)" },
    { id: 46, value: "3S000", name: "K7 더 프레스티지 (2011~2012)" },
    { id: 47, value: "3S000", name: "투싼IX (2009~2013)" },
    { id: 48, value: "3S000", name: "스포티지 R (2010~2013)" },
    { id: 49, value: "3S000", name: "쏘렌토 R (2009~2012)" },
    { id: 50, value: "3S000", name: "베라크루즈 (2012~2015)" },

    { id: 51, value: "3R500", name: "더 뉴 K7 (2012~2016)" },
    { id: 52, value: "3R500", name: "K7 (2013~2016)" },
    { id: 53, value: "3R500", name: "K7 하이브리드(2013~2016)" },
    { id: 54, value: "3R500", name: "더 뉴 K9 (2014~2018)" },
    { id: 55, value: "3R500", name: "더 뉴 맥스크루즈 (2015~2019)" },
    { id: 56, value: "3R500", name: "모하비 (버튼확인) (2009.11~2010.12, 2011.06~2015.08)" },

    { id: 57, value: "2W500", name: "산타페 DM (2012~2015)" },
    { id: 58, value: "2W500", name: "산타페 더 프라임 (2015~2018)" },
    { id: 59, value: "2W500", name: "맥스크루즈 (2013~2015)" },
  ]

  constructor() { 
    this.keyImgSrc = "";
  }
  ngOnInit() {
  }

  changeSearchText() {
    const list = this.keyList.filter(x => x.name === this.searchText)[0];

    document.getElementById("keyimg").style.display = 'block';
    this.keyImgSrc = "assets/img/brackets/" + list.value + ".png";

    console.log(this.keyImgSrc);
    
  }

  keyChange(e){
    const name = e.target.value;
    const list = this.keyList.filter(x => x.name === name)[0];
    this.keyValue = list.value + " " + name;
    console.log(list.value);
  }
}
