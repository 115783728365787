import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class EnsureBrandKeyplus  {
  constructor(private router: Router, private auth: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.admin.brand_type == null) {
      return true;
    }
    else {
      console.log(this.auth.isCurrentUser);
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}