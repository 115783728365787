import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import { AuthService } from '../tools/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as Format from '../tools/services/format.service';
import * as BrandCvt from '../tools/services/brandConverter.service';
import * as Lsm from '../tools/services/localstorageManager.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-userdetail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  private format = Format;
  private brandCvt = BrandCvt;
  private lsm = Lsm;

  private user: any;
  private userMobileSettings: any;
  private selectedCar: any = null; 
  private cars: any;
  private sharedCars: any;
  private friends: any;
  private carshares: any;
  private carshareType: string;

  private isUpdateAndCreate: boolean = false;
  private isFirst: boolean = true;

  constructor(private rest: RestService,
    private router: Router,
    private actRouter: ActivatedRoute,
    private auth: AuthService, 
    private translate: TranslateService
    ) {
    this.user = {
      seq: "",
      user_id: ""
    }
    this.carshareType = "_";

    console.log("pass user data = ", this.user);
  }
  ngOnInit() {
    this.user.seq = this.actRouter.snapshot.params['user_seq'];
    this.user.user_id = this.actRouter.snapshot.params['user_id'];
    // 스페셜원, 만도가 아니면
    // 사용자가 공유받은 또는 소유한 차량 중에 국가코드가 있다면 조회할 수 있도록 조치
    // 로직
    /*
    1. 사용자 조회 : 만도, 키플인지 확인
    2. 차량 조회 : 접근가능한 사용자인지 확인
    3. 차량 중에 국가코드에 해당하는 값이 있는지 확인
    */
    this.getGeneralUserBySeq();
    // this.getCarListByUserSeq();
    // this.getGeneralUserMobileSettings();
    // this.getCarShareListByListId(this.user.user_id);
    // this.getFriendListByUserId()
    // this.permissionRestrictions();
  }
  permissionRestrictions() {
    if (this.auth.admin.role_id < 8) {
      this.isUpdateAndCreate = false;
    }
    else {
      this.isUpdateAndCreate = true
    }
  }
  getGeneralUserBySeq() {
    this.rest.getGeneralUserBySeq(this.user.seq).subscribe(
      res => {
        if (res["success"]) {
          const resultUser = res["user"];
          if (this.auth.hasUserPermssionBrandType(resultUser.brand_type)) {
            this.user = res["user"];
            console.log("this.user = ", this.user);
            this.getCarListByUserSeq();
          }else {
            alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
            this.router.navigate(['/']);
          }
          // this.getCarShareLogListCountByUserId();
          // this.getCarShareLogListByUserId();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  }
  getGeneralUserMobileSettings() {
    this.rest.getGeneralUserMobileSettings(this.user.seq).subscribe(
      res => {
        if (res["success"]) {
          this.userMobileSettings = res["user_mobile_settings"];
          console.log("this.userMobileSettings = ", this.userMobileSettings);
        }
      });
  }
  updateGeneralUserPw(userPwFrom: NgForm) {
    const userObj = userPwFrom.value;
    userObj.seq = this.user.seq;
    if (userObj.seq == -1) {
      alert(this.translate.instant("ALERT.ERROR.MEMEBER_INFO"));
      return;
    }
    else if (this.format.isEmpty(userObj.user_pw)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PASSWORD"));
      return
    }

    const result = confirm(this.translate.instant("ALERT.CONFIRM.UPDATE.PASSWORD"));
    if (!result) {
      return;
    }

    this.rest.updateGeneralUserPassword(userObj).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          userPwFrom.reset();
          this.getGeneralUserBySeq();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.COMMON"));
      });
  }
  getCarListByUserSeq() {
    // this.rest.getCarListByUserSeq(this.user.seq).subscribe(
    //   res => {
    //     if (res["success"]) {
    //       this.cars = res["cars"];
    //       console.log("cars = ", this.cars);
    //       console.log("cars = ", res);
    //     }
    //     else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    //   });
    this.rest.getAllCarListByUserSeq(this.user.seq).subscribe(
        res => {
          if (res["success"]) {
            const myCars = res["my_cars"].map(car => ({ ...car, type: 'MINE' }));
            const shareCars = res["share_cars"].map(car => ({ ...car, type: 'SHARE' }));
            const partnerCars = res["partner_cars"].map(car => ({ ...car, type: 'PARTNER' })); 

            /*
            myCars
            shareCars
            partnerCars
             */
            const resultCars = [...myCars, ...shareCars, ...partnerCars];
            if (this.isFirst) {
              this.isFirst = false;
              if (this.auth.isSponeCompany() 
                || this.auth.isMandoCompany() 
                || (this.user.country_code && (this.user.country_code === this.auth.getCompanyCode()))
                || this.auth.hasUserPermssionByCars(resultCars)
              ) {
                this.cars = resultCars;
                console.log("cars = ", this.cars);
                this.getGeneralUserMobileSettings();
                this.getCarShareListByListId(this.user.user_id);
                this.getFriendListByUserId()
                this.permissionRestrictions();
              }else {
                alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
                this.router.navigate(['/']);
              }
            }else {
              this.cars = resultCars;
            }
          }
          else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
        });
  }
  createCar(carForm: NgForm) {
    const carObj = carForm.value;
    carObj.user_seq = this.user.seq;
    if (carObj.user_seq == -1) {
      alert(this.translate.instant("ALERT.ERROR.MEMEBER_INFO"));
      return;
    }
    else if (this.format.isEmpty(carObj.car_number)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CAR_NUMBER"));
      return
    }
    else if (this.format.isEmpty(carObj.car_maker)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CAR_MAKER"));
      return
    }
    else if (this.format.isEmpty(carObj.car_type)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CAR_TYPE"));
      return
    }
    else if (this.format.isEmpty(carObj.device_name)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.DEVICE_NAME"));
      return
    }
    else if (this.format.isEmpty(carObj.device_id)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.DEVICE_ID"));
      return
    }
    else if (this.format.isEmpty(carObj.device_pw)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.DEVICE_PASSWORD"));
      return
    }
    if (!this.format.regEexCheck(carObj.car_number, 'carNum')) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_CAR_NUMBER"));
      return
    }

    const result = confirm(this.translate.instant("ALERT.CONFIRM.ADD.CAR")
      + "\n\n" + this.translate.instant("CAR") + " " + this.translate.instant("NUMBER") + ": " + carObj.car_number
      + "\n" + this.translate.instant("CAR_MAKER") + ": " + carObj.car_maker
      + "\n" + this.translate.instant("CAR") + " " + this.translate.instant("TYPE") + ": " + carObj.car_type
      + "\n" + this.translate.instant("DEVICE") + " " + this.translate.instant("NAME") + ": " + carObj.device_name
      + "\n" + this.translate.instant("DEVICE") + " " + this.translate.instant("ID") + ": " + carObj.device_id
      + "\n" + this.translate.instant("DEVICE") + " " + this.translate.instant("PASSWORD") + ": " + carObj.device_pw
    );
    if (!result) {
      return;
    }

    this.rest.createCar(carObj).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          carForm.reset();
          this.getCarListByUserSeq();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
      });
  }
  deleteCar(carSeq) {
    if (this.auth.admin.role_id < 4) {
      alert(this.translate.instant("ALERT.ERROR.HTTP.401"));
      return;
    }
    const result = confirm(this.translate.instant("ALERT.CONFIRM.DELETE.COMMON"));
    if (!result) return;
    this.rest.deleteCar(carSeq).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          this.getCarListByUserSeq();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.COMMON"));
      });
  }
  settingSourcePropertyName(source) {
    for (let i = 0; i <= source.length - 1; i++) {
      source[i]['admin_company_name'] = source[i]['admin_company']['name'];
      source[i]['admin_company_address'] = source[i]['admin_company']['address'];
    }
  }
  getCarShareListByListId(userId) {
    this.rest.getCarShareListByUserId(this.carshareType, userId).subscribe((res) => {
      if (res["success"]) {
        this.carshares = res["carshares"];
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    });
  }
  createFriend(friendFrom: NgForm) {
    const friendObj = friendFrom.value;
    friendObj.user_id = this.user.user_id;
    friendObj.country_code = "KR"

    if (this.format.isEmpty(friendObj.friend_name)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.NICKNAME"));
      return
    }
    else if (this.format.isEmpty(friendObj.friend_number)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PHONE_NUMBER"));
      return
    }
    else if (!this.format.regEexCheck(friendObj.friend_number, "mobilePhonNum")) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_PHONE_NUMBER"));
      return
    }

    const result = confirm(this.translate.instant("ALERT.CONFIRM.ADD.FRIEND")
      + "\n\n"+ this.translate.instant("NICKNAME") +": " + friendObj.friend_name
      + "\n"+ this.translate.instant("PHONE_NUMBER") +": " + friendObj.friend_number
    );

    if (!result) {
      return;
    }

    if (this.lsm.getCurrentUserBrandByBrandValue() == BrandCvt.BRAND_TYPE_MANDO) {
      friendObj.friend_number = BrandCvt.makeMandoId(friendObj.friend_number)
    }

    if (friendObj.friend_number == friendObj.user_id) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.SAME_PHONE_NUMBER"));
      return
    }

    this.rest.createFriend(friendObj).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          friendFrom.reset();
          this.getFriendListByUserId();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
      });
  }
  getFriendListByUserId() {
    this.rest.getFriendListByUserId(this.user.user_id).subscribe(
      res => {
        if (res["success"]) {
          this.friends = res["friends"];
          console.log("friends = ", this.friends);
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  }
  selectCar(car: any): void {
    if (this.selectedCar && this.selectedCar.seq === car.seq) {
      // If the same car is clicked again, toggle the detail visibility off
      this.selectedCar = null;
    } else {
      // Set the selected car to the clicked car
      this.selectedCar = car;
    }
  }
  navigateToUser(userSeq: number, ownerId: number): void {
    this.router.navigateByUrl(`/user/${userSeq}/${ownerId}`);
  }
}
