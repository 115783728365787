import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Format from "../tools/services/format.service";
import * as BrandCvt from "../tools/services/brandConverter.service";
import * as Lsm from '../tools/services/localstorageManager.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  title = "Login - KeyPlus Admin";
  ipAddress: string = "-1";
  private loginObj: any = {
    user_id: "",
    user_pw: "",
    user_brand_type: ""
  };
  private lsm = Lsm;
  private format = Format;
  private brandCvt = BrandCvt;

  constructor(
    private router: Router,
    private rest: RestService,
    private auth: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loginObj.user_id = this.lsm.getValueLoginId();
    this.loginObj.user_pw = this.lsm.getValueLoginPw();
    const brandType = this.lsm.getValueLoginBrandType();
    if (brandType) {
      this.loginObj.user_brand_type = brandType;
    }else {
      this.loginObj.user_brand_type = "";
    }
  }

  enter(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.login();
    }
  }
  login() {
    const id = this.loginObj.user_id;
    const password = this.loginObj.user_pw;
    const brand = this.loginObj.user_brand_type;

    if (this.format.isEmpty(id)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.ID_PHONE_NUMBER"));
      return;
    } else if (this.format.isEmpty(password)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PASSWORD"));
      return;
    } else if (this.format.isEmpty(brand)) {
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.COMPANY"));
      return;
    }
    this.lsm.setValueLoginId(id);
    this.lsm.setValueLoginPw(password);
    this.lsm.setValueLoginBrandType(brand);

    const _id =
      brand == this.brandCvt.BRAND_TYPE_MANDO
        ? this.brandCvt.makeMandoId(id)
        : id;

    const model = {
      id: _id,
      password: password,
      device_id: this.ipAddress,
      device_type: 1,
    };
    this.auth.login(model).subscribe((data) => {
      console.log("login component inputData = ", data);

      if (data["code"] == 0) {
        this.auth.setLogin(data);
        this.router.navigate(["/users"]);
      } else if (data["code"] == 8) {
        alert(this.translate.instant("ALERT.ERROR.DUPLICATE.LOG_IN"));
        console.log("alread login from another device.");
      } else {
        alert(data["message"]);
        console.log("로그인 실패");
      }
    });
  }
}
