import { Config } from "../../config/config";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { tap, catchError, retry } from "rxjs/operators";
import { throwError } from "rxjs";
import * as lsm from "./localstorageManager.service";

declare var getLocalIP: any;

@Injectable()
export class AuthService {
  private url = Config.REST_API_SERVER;
  private mandoSn = Config.MANDO_SN;
  private sponeSn = Config.SPONE_SN;
  private headers: any;
  private localIp: any;

  public isCurrentUser: boolean = false;
  public authToken: string;
  public admin = {
    id: undefined,
    name: undefined,
    tel: undefined,
    role_id: undefined,
    company_sn: undefined,
    brand_type: undefined,
    admin_company: undefined,
  };
  constructor(private router: Router, private http: HttpClient) {
    getLocalIP().then((ipAddr) => {
      this.localIp = ipAddr;
    });
  }
  login(userInfo) {
    userInfo.device_id = this.localIp;
    return this.http.post(this.url + "/unauth/login", userInfo).pipe(
      tap(
        (next) => console.log("login() start"),
        (error) => console.log("login error ", error),
        () => console.log("login() complete")
      ),
      catchError(this.handleError)
    );
  }
  setLogin(loginResultValue) {
    this.isCurrentUser = true;
    this.authToken = loginResultValue.token;
    this.admin = loginResultValue.user;

    delete this.admin["password"];
    console.log("this.admin", this.admin);

    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-auth-token": this.authToken,
    });

    lsm.setValueCurrentUser(this.admin);
    lsm.setValueToken(loginResultValue.token);
  }
  setLogout() {
    const p = new Promise((resolve, reject) => {
      if (!this.authToken) {
        this.authToken = lsm.getValueToken();
      }
      resolve(this.authToken);
    });
    p.then((token) => {
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": this.authToken,
      });
      return this.http
        .post(
          this.url + "/admin/user/logout/" + this.admin.id + "/device/1",
          "",
          { headers: headers }
        )
        .pipe(
          retry(3),
          tap(
            (next) => console.log("logout() start", next),
            (error) => console.log("logout error ", error),
            () => console.log("logout() complete")
          ),
          catchError(this.handleError)
        )
        .subscribe(
          (res) => {
            console.log(res);
            if (res["success"]) {
              this.isCurrentUser = false;
              this.authToken = undefined;
              this.admin = undefined;
            } else {
              console.log("logout false!!!");
            }
          },
          (error) => {
            console.log(error);
            this.isCurrentUser = false;
            this.authToken = undefined;
            this.admin = undefined;
            lsm.removeValueCurrentUser();
            lsm.removeValueToken();
            lsm.removeValueLoginId();
            lsm.removeValueLoginPw();
            this.router.navigate(["/login"]);
          },
          () => {
            lsm.removeValueCurrentUser();
            lsm.removeValueToken();
            lsm.removeValueLoginId();
            lsm.removeValueLoginPw();
            this.router.navigate(["/login"]);
            console.log("logout succes!!");
          }
        );
    });
  }
  isSponeCompany() {
    return this.admin.company_sn == this.sponeSn;
  }
  isMandoCompany() {
    return this.admin.company_sn == this.mandoSn;
  }
  isSameComany(companySn) {
    return this.admin.company_sn == companySn;
  }
  getCompanyCode(): string {
    //
    // country_code와 동일
    const adminCompany = this.admin.admin_company;
    if (typeof adminCompany === 'object' && adminCompany 
      && typeof adminCompany.code === 'string' && typeof adminCompany.code
    ) {
      return adminCompany.code;
    }
    return "";
  }
  hasUserPermssionBrandType(brandType) {
    return this.admin.brand_type == brandType;
  }
  hasUserPermssionByCars(cars) {
    if (!Array.isArray(cars))
      return false;

    if (cars.length < 1)
      return false;

    const companyCode = this.admin.admin_company.code;
    return cars.some(car => car.device_sn != null && car.device_sn.startsWith(companyCode));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("client error! :", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
