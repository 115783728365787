import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { RestService } from "../../app/tools/services/rest.service";
import * as Format from "../tools/services/format.service";
import * as BrandCvt from "../tools/services/brandConverter.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-carshares",
  templateUrl: "./carshares.component.html",
  styleUrls: ["./carshares.component.css"],
})
export class CarsharesComponent implements OnInit {
  private searchText: string;
  private searchTextLog: string;
  private orderColumn: string;
  private isDesc: boolean;
  private orderColumnLog: string;
  private isDescLog: boolean;

  private pageObj: any;
  private pageObjLog: any;

  private carshares: any[];
  private carsharesFilteredCount = { count: 0 };

  private carshareLogs: any[];
  private carshareLogsCount: number;
  private format = Format;
  private brandCvt = BrandCvt;

  private carshareType: string;

  constructor(private rest: RestService, private translate: TranslateService) {
    this.orderColumn = "created";
    this.isDesc = true;
    this.orderColumnLog = "created";
    this.isDescLog = true;
    this.searchTextLog = "";
    this.carshareType = "_";

    this.pageObj = {
      view_count: 10,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1,
    };
    this.pageObjLog = {
      view_count: 10,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1,
    };
  }
  ngOnInit() {
    this.getCarShareList();
    this.getCarShareLogListCount();
    this.getCarShareLogList();
  }
  enter(event: KeyboardEvent, type, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(type, searchvalue);
      if (type == "main") {
        this.renewalPage(type);
      } else if (type == "log") {
        this.getCarShareLogListCount();
        this.getCarShareLogList();
      }
    }
  }
  setSearchText(type, val) {
    console.log(type, val);
    if (type == "main") {
      this.pageObj.currentblock = 1;
      this.pageObj.currentpage = 1;
      this.searchText = val;
    } else if (type == "log") {
      this.pageObjLog.currentblock = 1;
      this.pageObjLog.currentpage = 1;
      this.searchTextLog = val;
    }
  }
  setOrder(type, column) {
    if (type == "main") {
      if (this.orderColumn == column) this.isDesc = !this.isDesc;
      else {
        this.isDesc = false;
        this.orderColumn = column;
      }
    } else if (type == "log") {
      if (this.orderColumnLog == column) this.isDescLog = !this.isDescLog;
      else {
        this.isDescLog = false;
        this.orderColumnLog = column;
      }
      this.getCarShareLogList();
    }
  }
  renewalPage(type) {
    this.pageSettingPageData(type);
    this.pageBtnActivateYn(type);
    this.pageMoveBlockBtnDisabledYn(type);
  }
  getCarShareList() {
    this.rest.getCarShareList(this.carshareType).subscribe((res) => {
      if (res["success"]) {
        this.carshares = res["carshares"];
        this.carsharesFilteredCount.count = this.carshares.length;
        this.pageSettingPageData("main");
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    });
  }
  convertLogQueryParams(param) {
    if (param == "sort") {
      if (this.isDescLog == true) return "desc";
      else return "asc";
    } else if (param == "page") {
      return this.pageObjLog.view_count * (this.pageObjLog.currentpage - 1);
    } else if (param == "search") {
      if (
        this.searchTextLog == undefined ||
        this.searchTextLog == "" ||
        this.searchTextLog == null
      )
        return "_";
      else return this.searchTextLog;
    }
  }
  getCarShareLogList() {
    let _sort = this.convertLogQueryParams("sort");
    let _page = this.convertLogQueryParams("page");
    let _search = this.convertLogQueryParams("search");
    this.rest
      .getCarShareLogList(
        _search,
        this.orderColumnLog,
        _sort,
        this.pageObjLog.view_count,
        _page
      )
      .subscribe((res) => {
        if (res["success"]) {
          this.carshareLogs = res["carshare_logs"];
          console.log("this.carshareLogs =", this.carshareLogs);
        } else
          alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  }
  getCarShareLogListCount() {
    let _search = this.convertLogQueryParams("search");
    this.rest.getCarShareLogListCount(_search).subscribe((res) => {
      if (res["success"]) {
        this.carshareLogsCount = res["carshare_logs_count"][0].totalItem;
      } else
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    });
  }
  pageSettingPageData(type) {
    if (type == "main") {
      this.pageObj.totalpage = Math.ceil(
        this.carsharesFilteredCount.count / this.pageObj.view_count
      );
      if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
      this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

      if (this.pageObj.currentblock > this.pageObj.totalblock)
        this.pageObj.currentblock = this.pageObj.totalblock;
      if (this.pageObj.currentpage > this.pageObj.totalpage) {
        this.pageMovePage(type, this.pageObj.totalpage % 5);
      }
    } else if (type == "log") {
      this.pageObjLog.totalpage = Math.ceil(
        this.carshareLogsCount / this.pageObjLog.view_count
      );
      if (this.pageObjLog.totalpage == 0) this.pageObjLog.totalpage = 1;
      this.pageObjLog.totalblock = Math.ceil(this.pageObjLog.totalpage / 5);

      if (this.pageObjLog.currentblock > this.pageObjLog.totalblock)
        this.pageObjLog.currentblock = this.pageObjLog.totalblock;
      if (this.pageObjLog.currentpage > this.pageObjLog.totalpage) {
        this.pageMovePage(type, this.pageObjLog.totalpage % 5);
      }
    }
  }
  pageGetPage(type) {
    this.renewalPage(type);

    if (type == "main") {
      if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
      let value = this.pageObj.totalpage - (this.pageObj.currentblock - 1) * 5;
      if (value == 0) value = 5;

      return value;
    } else if (type == "log") {
      if (this.pageObjLog.currentblock == 1) return this.pageObjLog.totalpage;
      let value =
        this.pageObjLog.totalpage - (this.pageObjLog.currentblock - 1) * 5;
      if (value == 0) value = 5;

      return value;
    }
  }
  pageMoveBlock(type, rl) {
    let pastIndex;
    if (type == "main") {
      pastIndex =
        this.pageObj.currentpage - (this.pageObj.currentblock - 1) * 5;

      if (rl == 1) this.pageObj.currentblock--;
      else if (rl == 2) this.pageObj.currentblock++;

      this.pageObj.currentpage = (this.pageObj.currentblock - 1) * 5 + 1;
    } else if (type == "log") {
      pastIndex =
        this.pageObjLog.currentpage - (this.pageObjLog.currentblock - 1) * 5;

      if (rl == 1) this.pageObjLog.currentblock--;
      else if (rl == 2) this.pageObjLog.currentblock++;

      this.pageObjLog.currentpage = (this.pageObjLog.currentblock - 1) * 5 + 1;
      this.getCarShareLogList();
    }
    this.pageMoveBlockBtnDisabledYn(type);
    this.pageBtnActivateYn(type);
  }
  pageMovePage(type, pagenum) {
    if (type == "main") {
      this.pageObj.currentpage = (this.pageObj.currentblock - 1) * 5 + pagenum;
    } else if (type == "log") {
      this.pageObjLog.currentpage =
        (this.pageObjLog.currentblock - 1) * 5 + pagenum;
      this.getCarShareLogList();
    }
    this.renewalPage(type);
  }
  setPageToFirst(type) {
    if (type == "main") {
      this.pageObj.currentpage = 1;
      this.pageObj.currentblock = 1;
    } else if (type == "log") {
      this.pageObjLog.currentpage = 1;
      this.pageObjLog.currentblock = 1;
    }
  }
  changeViewCount(type) {
    if (type == "main") {
      this.pageObj.currentpage = 1;
      this.pageObj.currentblock = 1;
    } else if (type == "log") {
      this.pageObjLog.currentpage = 1;
      this.pageObjLog.currentblock = 1;
      this.getCarShareLogList();
    }
    this.renewalPage(type);
  }

  pageBtnActivateYn(type) {
    let pastId;
    let currId;
    if (type == "main") {
      let currentIndex = this.pageObj.currentpage % 5;
      if (currentIndex == 0) currentIndex = 5;
      currId = "page" + currentIndex;

      for (let i = 1; i <= 5; i++) {
        pastId = "page" + i;
        document.getElementById(pastId).classList.remove('btn-dark');
        document.getElementById(pastId).classList.add('btn-outline-dark');
      }
    } else if (type == "log") {
      let currentIndex = this.pageObjLog.currentpage % 5;
      if (currentIndex == 0) currentIndex = 5;
      currId = "pageLog" + currentIndex;

      for (let i = 1; i <= 5; i++) {
        pastId = "pageLog" + i;
        document.getElementById(pastId).classList.remove('btn-dark');
        document.getElementById(pastId).classList.add('btn-outline-dark');
      }
    }
    document.getElementById(currId).classList.add('btn-dark');
    document.getElementById(currId).classList.remove('btn-outline-dark');
  }
  pageMoveBlockBtnDisabledYn(type) {
    if (type == "main") {
      if (this.pageObj.currentblock >= this.pageObj.totalblock) {
        document.getElementById("page_next").classList.add("disabled");
      } else {
        document.getElementById("page_next").classList.remove("disabled");
      }

      if (this.pageObj.currentblock == 1) {
        document.getElementById("page_prev").classList.add("disabled");
      } else {
        document.getElementById("page_prev").classList.remove("disabled");
      }
    } else if (type == "log") {
      if (this.pageObjLog.currentblock >= this.pageObjLog.totalblock) {
        document.getElementById("pageLog_next").classList.add("disabled");
      } else {
        document.getElementById("pageLog_next").classList.remove("disabled");
      }

      if (this.pageObjLog.currentblock == 1) {
        document.getElementById("pageLog_prev").classList.add("disabled");
      } else {
        document.getElementById("pageLog_prev").classList.remove("disabled");
      }
    }
  }

  convertTypeToString(type) {
    if (type == 1) return this.translate.instant("NORMAL_SHARING");
    else if (type == 2) return this.translate.instant("CONTINUOUS_SHARING");
  }

  returnShare(type, seq) {
    const result = confirm(this.translate.instant("ALERT.CONFIRM.SHARE.REVOKE"));
    if (result) {
      if (type == "share") {
        this.rest.putCarShare(seq).subscribe((res) => {
          if (res["success"]) {
            alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
            this.pageObj.currentblock = 1;
            this.pageMovePage("main", 1);
            this.getCarShareList();
          } else {
            alert(this.translate.instant("ALERT.ERROR.FAIL.SHARE.REVOKE"));
          }
        });
      } else if (type == "partner") {
        this.rest.putCarPartner(seq).subscribe((res) => {
          if (res["success"]) {
            alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
            this.pageObj.currentblock = 1;
            this.pageMovePage("main", 1);
            this.getCarShareList();
          } else {
            alert(this.translate.instant("ALERT.ERROR.FAIL.SHARE.REVOKE"));
          }
        });
      }
    }
  }
  postCarShare(carshareForm: NgForm) {
    const carshareObj = carshareForm.value;
    if (this.format.isEmpty(carshareObj.type)) {
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.SHARE_TYPE"));
      return;
    } else if (this.format.isEmpty(carshareObj.car_seq)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CAR_UNIQUE_NUMBER"));
      return;
    } else if (this.format.isEmpty(carshareObj.sender_tel)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.OWNER_PHONE_NUMBER"));
      return;
    } else if (this.format.isEmpty(carshareObj.receiver_tel)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.SHARER_PHONE_NUMBER"));
      return;
    }

    if (!this.format.regEexCheck(carshareObj.car_seq, "number")) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_CAR_UNIQUE_NUMBER"));
      return;
    } else if (
      !this.format.regEexCheck(carshareObj.sender_tel, "number") ||
      !this.format.regEexCheck(carshareObj.receiver_tel, "number")
    ) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.PHONE_NUMBER_ONLY_NUMBERS"));
      return;
    } else if (
      !this.format.regEexCheck(carshareObj.sender_tel, "mobilePhonNum") ||
      !this.format.regEexCheck(carshareObj.receiver_tel, "mobilePhonNum")
    ) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_PHONE_NUMBER"));
      return;
    }

    const result = confirm(
      this.translate.instant("ALERT.CONFIRM.ADD.SHARE") +
        "\n\n" + this.translate.instant("TYPE") + ": " +
        this.convertTypeToString(carshareObj.type) +
        "\n"+ this.translate.instant("CAR") + " " + this.translate.instant("UNIQUE_NUMBER") +": " +
        carshareObj.car_seq +
        "\n" + this.translate.instant("OWNER") + " " + this.translate.instant("PHONE_NUMBER") + ": " +
        carshareObj.sender_tel +
        "\n" + this.translate.instant("SHARER") + " " + this.translate.instant("PHONE_NUMBER") + ": " +
        carshareObj.receiver_tel
    );
    if (result) {
      if (carshareObj.type == 1) {
        this.rest
          .postCarShare(
            carshareObj.car_seq,
            carshareObj.sender_tel,
            carshareObj.receiver_tel
          )
          .subscribe((res) => {
            if (res["success"]) {
              alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
              carshareForm.reset();
              this.postCarShareSucc();
            } else {
              alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
            }
          });
      } else if (carshareObj.type == 2) {
        this.rest
          .postCarPartner(
            carshareObj.car_seq,
            carshareObj.sender_tel,
            carshareObj.receiver_tel
          )
          .subscribe((res) => {
            if (res["success"]) {
              alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
              carshareForm.reset();
              this.postCarShareSucc();
            } else {
              alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
            }
          });
      }
    }
  }
  postCarShareSucc() {
    this.orderColumn = "created";
    this.isDesc = true;
    let element = <HTMLSelectElement>document.getElementById("shareType");
    element.value = "";
    this.pageObj.currentblock = 1;
    this.pageMovePage("main", 1);
    this.getCarShareList();
  }
  ToggleAddForm() {
    var addformdiv = document.getElementById("carShareAddForm");
    if (addformdiv.style.display == "none") addformdiv.style.display = "block";
    else addformdiv.style.display = "none";
  }
}
