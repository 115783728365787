import { Component, OnInit } from "@angular/core";
import { RestService } from "../../tools/services/rest.service";
import { AuthService } from "../..//tools/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as Format from "../../tools/services/format.service";
import * as Lsm from "../../tools/services/localstorageManager.service";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-noticedetail",
  templateUrl: "./notice-detail.component.html",
  styleUrls: ["./notice-detail.component.css"],
})
export class NoticeDetailComponent implements OnInit {
  private format = Format;
  private lsm = Lsm;

  private notice: any;
  private languages: any;
  private isUpdate: boolean;
  private langTypes: any;
  private languageToAdd: any;
  private titleToAdd: any;
  private contentToAdd: any;

  constructor(
    private rest: RestService,
    private router: Router,
    private actRouter: ActivatedRoute,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    this.notice = {
      seq: "",
    };
    this.languageToAdd = "";
    this.titleToAdd = "";
    this.contentToAdd = "";
  }
  ngOnInit() {
    this.notice.seq = this.actRouter.snapshot.params["notice_seq"];
    this.rest.getSupportedLanguages().subscribe((res) => {
      if (res["success"]) {
        this.langTypes = res["result"];
        console.log("this.langTypes = ", this.langTypes);
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    });
    this.getNoticeBySeq();
    this.isBlock();
  }

  isBlock() {
    if (this.auth.admin.role_id == 8) this.isUpdate = true;
    else this.isUpdate = false;
    console.log("isUpdate = " + this.isUpdate);
  }
  getNoticeBySeq() {
    this.rest.getNoticeBySeq(this.notice.seq).subscribe((res) => {
      if (res["success"]) {
        this.notice = res["notice"];
        console.log("this.notice = ", this.notice);
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
    });
  }
  updateNotice() {
    // if (this.userObj.user_type == this.user.user_type) return;
    // const userbody = {
    //   user_id: this.user.user_id,
    //   user_nickname: this.user.user_nickname,
    //   user_type: this.userObj.user_type,
    //   device_id: this.user.device_id,
    //   agreement_location: this.user.agreement_location,
    //   agreement_marketing: this.user.agreement_marketing,
    //   agreement_personal_info: this.user.agreement_personal_info,
    //   agreement_push: this.user.agreement_push,
    //   agreement_service: this.user.agreement_service,
    //   is_password_change: this.user.is_password_change,
    //   push_token: this.user.push_token,
    //   push_type: this.user.push_type,
    // };
    // const result = confirm(
    //   this.translate.instant("ALERT.CONFIRM.UPDATE.COMMON")
    // );
    // if (!result) return;
    // this.rest.updateGeneralUser(userbody).subscribe((res) => {
    //   if (res["success"]) {
    //     alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
    //     this.getGeneralUserBySeq();
    //   } else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.COMMON"));
    // });
  }

  deleteNotice(noticeSeq) {
    if (this.auth.admin.role_id < 4) {
      alert(this.translate.instant("ALERT.ERROR.HTTP.401"));
      return;
    }
    const result = confirm(
      this.translate.instant("ALERT.CONFIRM.DELETE.COMMON")
    );
    if (!result) return;
    this.rest.deleteNotice(noticeSeq).subscribe((res) => {
      if (res["success"]) {
        alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
      } else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.COMMON"));
    });
  }

  addNoticeLanguage(frm: NgForm) {
    const noticeObj = {
      seq: this.notice.seq,
      language: frm.value.lang,
      title: frm.value.title,
      content: frm.value.content,
    };
    console.log(noticeObj);
    if (this.format.isEmpty(noticeObj.language)) {
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.LANGUAGE"));
      return;
    } else if (this.format.isEmpty(noticeObj.title)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.SUBJECT"));
      return;
    } else if (this.format.isEmpty(noticeObj.content)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CONTENTS"));
      return;
    }

    for (let lang of this.notice.translates) {
      if (lang.language === noticeObj.language) {
        alert(this.translate.instant("ALERT.ERROR.DUPLICATE.LANG"));
        return;
      }
    }
    if (
      confirm(
        this.translate.instant("ALERT.CONFIRM.ADD.NOTICE_LANG") +
          "\n\n" +
          this.translate.instant("LANGUAGE") +
          ": " +
          this.translate.instant("LANG_ISO." + noticeObj.language) +
          "(" +
          noticeObj.language +
          ")\n" +
          this.translate.instant("SUBJECT") +
          ": " +
          noticeObj.title +
          "\n" +
          this.translate.instant("CONTENT") +
          ": " +
          noticeObj.content
      )
    ) {
      this.rest.addNoticeLanguage(noticeObj).subscribe((res) => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          frm.reset();
          this.getNoticeBySeq();
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
        }
      });
    }
  }

  ToggleAddForm() {
    var addformdiv = document.getElementById("appVerAddForm");
    if (addformdiv.style.display == "none") addformdiv.style.display = "block";
    else addformdiv.style.display = "none";
  }
}
