import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../tools/services/auth.service';

import * as Format from '../tools/services/format.service';
import * as BrandCvt from '../tools/services/brandConverter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admindetail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.css']
})
export class AdminDetailComponent implements OnInit {
  private format = Format;
  private brandCvt = BrandCvt;

  private admin: any;
  private roles = new Array();
  
  private adminRoleId: number;
  private isUpdate: boolean;
  constructor(private router: Router, private rest: RestService, private actRouter: ActivatedRoute, private auth: AuthService, private translate: TranslateService) {
    this.admin = {
      id: ""
    }
  }

  ngOnInit() {
    this.admin.id = this.actRouter.snapshot.params['admin_id'];

    this.getAdminById();
  }
  permissionRestrictions() {
    if (this.auth.admin.role_id < 8) {
      if (this.auth.admin.role_id > this.admin.role_id) {
        this.isUpdate = true;
      } else {
        this.isUpdate = false;
      }
    }
    else {
      this.isUpdate = true;
    }
    this.getRoleList();
  }
  getRoleList() {
    this.roles = [];
    this.rest.getRoleList().subscribe(
      res => {
        if (res["success"]) {
          if (this.isUpdate) {
            res['role_list'].forEach(element => {
              if (element.id < this.auth.admin.role_id || this.auth.admin.role_id == 8)
                this.roles.push(element);
            });
          } else {
            this.roles = res['role_list'];
          }
          console.log("this.roles = ", this.roles);
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.GET.AUTH_LIST"));
        }
      });
  }
  getAdminById() {
    this.rest.getAdminById(this.admin.id).subscribe(
      res => {
        if (res["success"]) {
          const resultAdmin = res["user"];
          if (this.auth.isSponeCompany() || this.auth.isSameComany(resultAdmin.company_sn)) {
            this.admin = res["user"];
            console.log(res);
            console.log("admin = ", this.admin);
            this.adminRoleId = this.admin.role_id;
            this.permissionRestrictions();
          }else {
            this.router.navigate(['/']);
            alert(this.translate.instant("ALERT.ERROR.FAIL.GET.ADMIN_INFO"));
          }
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.GET.ADMIN_INFO"));
        }
      });
  }
  updateAdmin() {
    if (this.adminRoleId == this.admin.role_id)
      return;

    // const result = confirm("수정사항을 저장하시겠습니까?");
    const result = confirm(this.translate.instant("ALERT.CONFIRM.UPDATE.COMMON"));
    if (!result)
      return;

    const updateObj = {
      role_id: this.adminRoleId,
      id: this.admin.id
    };
    this.rest.updateAdmin(updateObj).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("UPDATE_ADMIN_INFO_COMPLETE"));
          this.getAdminById();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.ADMIN_INFO"));
      });
  }
}
