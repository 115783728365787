import { Component } from '@angular/core';
import { AuthService } from '../../tools/services/auth.service';
import { Router } from '@angular/router';
import { BaseMenuComponent } from '../basemenu'
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.css']
})
export class TopmenuComponent extends BaseMenuComponent {

  constructor(auth: AuthService, router: Router, translate: TranslateService) {
    super(auth, router, translate);
  }
  logout(){
    this.auth.setLogout();
  }
  navigateMyInfo() {
    this.router.navigate(["/myinfo"]);
  }
}
