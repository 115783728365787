import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import { AuthService } from '../tools/services/auth.service';
import * as Format from '../tools/services/format.service';
import * as Lsm from '../tools/services/localstorageManager.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.css']
})
export class SaleComponent implements OnInit {
  private format = Format;
  private lsm = Lsm;

  private viewCount: number;
  private sales: any[];
  private salesCount: number;
  private searchText: any;

  private salesFilteredCount = { count: 0 };

  constructor( private rest: RestService, private auth: AuthService, private translate: TranslateService ) {
    this.viewCount = 10;
    this.searchText = '_';
   }
  ngOnInit() {
    this.getSalesList();
  }
  enter(event: KeyboardEvent, searchvalue){
    if (event.keyCode == 13){
      this.setSearchText(searchvalue);
    }
  }
  setSearchText( val ){
    this.searchText = this.convertLogQueryParams(val);
    this.viewCount = 10;
    this.getSalesList();
  }
  getSalesList() {
    console.log("판매 목록 가져오기 시작");    
    this.rest.getSalesList(this.searchText, this.viewCount).subscribe(
      res => {
        if (res["success"]) {
          this.sales = res["logs"]["rows"];
          this.salesCount = res["logs"]["count"];
          this.salesFilteredCount.count = this.salesCount;
          console.log("res = ", res);
          console.log("this.sales = ", this.sales);
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      },
      error => console.log(error),
      () => console.log("getSalesList complate!!!"));
  }
  convertLogQueryParams(val) {
      if ( this.format.isEmpty(val) ) return '_';
      else return val;
  }

  createSaleLog(saleForm: NgForm){
    const saleObj = {
      user_id: this.auth.admin.id,
      user_name: this.auth.admin.name,
      user_tel: this.auth.admin.tel,
      company_sn: this.auth.admin.company_sn,
      customer_tel: saleForm.value.user_id,
      customer_name: saleForm.value.user_nickname,
      car_number: saleForm.value.car_number,
      brand_type: this.lsm.getCurrentUserBrandByBrandValue()
    }
    console.log("saleObj = ", saleObj);
    if( this.format.isEmpty(saleObj.customer_tel) ){
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_PHONE_NUMBER"));
      return;
    } else if ( !this.format.regEexCheck(saleObj.customer_tel, 'mobilePhonNum')){
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_PHONE_NUMBER"));
      return;
    } else if ( this.format.isEmpty(saleObj.customer_name) ){
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_NAME"));
      return;
    } else if ( this.format.isEmpty(saleObj.car_number) ){
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_CAR_NUMBER"));
      return;
    } else if ( !this.format.regEexCheck(saleObj.car_number, 'carNum')) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_CORRECTED_CAR_NUMBER"));
      return;
    }

    const result = confirm(this.translate.instant("ALERT.CONFIRM.ADD.SALE_LOG")
    + "\n\n"+ this.translate.instant("CUSTOMER") + " " + this.translate.instant("PHONE_NUMBER") +": " + this.format.setDataFormat(saleObj.customer_tel,'tel')
    + "\n"+ this.translate.instant("CUSTOMER") + this.translate.instant("NAME") + ": " + saleObj.customer_name
    + "\n"+ this.translate.instant("CUSTOMER") + " " +this.translate.instant("CAR") + this.translate.instant("NUMBER") + ": " + saleObj.car_number
    );
    if( !result ){
      return;
    }

    this.rest.createSaleLog(saleObj).subscribe(
      res => {
        console.log("res =", res);
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          saleForm.reset();
          this.getSalesList();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
      });
  }
  addViewCount(){
    this.format.addViewCount(this.viewCount, 10, ( view_count: number )=>{
      this.viewCount = view_count;
      this.getSalesList();
    });
  }
}
