import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Format from "../tools/services/format.service";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-company-code-list",
  templateUrl: "./company-code-list.component.html",
  styleUrls: ["./company-code-list.component.css"],
})
export class CompanyCodeListComponent implements OnInit {
  private searchText: string;

  private format = Format;
  private companyCodes: any;
  private companyCodesFilteredCount = { count: 0 };

  private viewCount: number;

  constructor(
    private router: Router,
    private rest: RestService,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    this.viewCount = 10;
  }

  ngOnInit() {
    this.getCompanyCodeList();
  }
  getCompanyCodeList() {
    this.rest.getCompanyCodeList().subscribe((res) => {
      if (res["success"]) {
        this.companyCodes = res["company_code_list"];
        console.log("this.companyCodes = ", this.companyCodes);
      } else {
        alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      }
    });
  }
  createCompanyCode(companyCodeForm: NgForm) {
    const companyCodeObj = {
      code: companyCodeForm.value.code,
      name: companyCodeForm.value.name,
      description: companyCodeForm.value.description,
    };

    console.log("companyObj = ", companyCodeObj);
    if (this.format.isEmpty(companyCodeObj.code)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CODE"));
      return;
    } else if (this.format.isEmpty(companyCodeObj.name)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CODE_NAME"));
      return;
    }

    if (
      confirm(
        this.translate.instant("ALERT.CONFIRM.ADD.COMPANY_CODE") +
          "\n\n" +
          this.translate.instant("CODE") +
          ": " +
          companyCodeObj.code +
          "\n" +
          this.translate.instant("NAME") +
          ": " +
          companyCodeObj.name +
          "\n" +
          this.translate.instant("DESCRIPTION") +
          ": " +
          companyCodeObj.name
      )
    ) {
      this.rest.createCompanyCode(companyCodeObj).subscribe((res) => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          companyCodeForm.reset();
          this.getCompanyCodeList();
        } else if (res.message.original.code == "ER_DUP_ENTRY") {
          alert(this.translate.instant("ALERT.ERROR.DUPLICATE.COMPANY_CODE"));
          return;
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
        }
      });
    }
  }
  addViewCount() {
    this.format.addViewCount(this.viewCount, 10, (view_count) => {
      this.viewCount = view_count;
      this.getCompanyCodeList();

      if (this.viewCount >= this.companyCodesFilteredCount.count) {
        let viewmore = document.getElementById("viewmore");
        viewmore.style.display = "none";
      }
    });
  }
}
