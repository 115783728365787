export function formatDateByUtc(date, byUtc){
    var d = new Date(date);
    let year, month, day, hour, minute, seconds;
    if(byUtc){
      year = '' + d.getUTCFullYear(); 
      month = '' + (d.getUTCMonth() + 1); 
      day = '' + d.getUTCDate();
      hour = '' + d.getUTCHours(); 
      minute = '' + d.getUTCMinutes(); 
      seconds = '' + d.getUTCSeconds();
    }
    else{
      year = ''+d.getFullYear();
      month = ''+(d.getMonth() +1);
      day = ''+d.getDate();
      hour = ''+d.getHours();
      minute = ''+d.getMinutes();
      seconds = ''+d.getSeconds();
    }
    if(month.length <2) month = '0' + month;
    if(day.length < 2) day = '0' + day;
    if(hour.length < 2) hour = '0' + hour;
    if(minute.length < 2) minute = '0' + minute;
    if(seconds.length < 2) seconds = '0' + seconds;
  
    return [year, month, day].join('-') + " " + [hour, minute, seconds].join(':');
}
export function setDataFormat(data: any, formatType: string) {
  if(data == null || undefined)
    return "-";
  if( formatType == "tel" ){
    //
    // 한국번호만 형식 변경
    // return data.toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");

    //
    // 한국, 베트남 번호 형식 변경
    const cleanedData = data.toString().replace(/[^\d]/g, ''); // 비숫자 문자 제거
    // 한국 전화번호 형식 (01x로 시작)
    if (/^01/.test(cleanedData)) {
      return cleanedData.replace(/^(01\d)(\d{3,4})(\d{4})$/, "$1-$2-$3");
    }
    // 베트남 전화번호 형식 (09x, 03x, 05x, 07x, 08x로 시작)
    else if (/^(09|03|05|07|08)/.test(cleanedData)) {
      return cleanedData.replace(/^(09\d|03\d|05\d|07\d|08\d)(\d{3})(\d{4})$/, "$1-$2-$3");
    }
    // 지정된 형식 외 번호
    else {
      return data; // 형식에 맞지 않는 경우 원본 데이터 반환
    }
  }
}
export function formatToRole(roleId, translate){
    if(roleId == 1) return translate.instant("BASIC");
    else if(roleId == 2) return translate.instant("AGENCY");
    else if(roleId == 4) return translate.instant("DISTRIBUTER_BRANCH");
    else if(roleId == 8) return translate.instant("SUPERVISOR");
}
export function formatToUserType(type, translate){
  if(type == 0) return translate.instant("NO_CHOICE");
  else if(type == 1) return translate.instant("OWNER");
  else if(type == 2) return translate.instant("WAITLIST");
}
export function formatToCarState(state, translate){
  if(state == 0) return translate.instant("USING");
  else if(state == 1) return translate.instant("SHARING");
  else if(state == 2) return translate.instant("WAITING");
  else if(state == 3) return translate.instant("SHARING") + "(" + translate.instant("USING") + ")";
}
export function formatToShareState(state, translate){
  if(state == 0) return translate.instant("SHARING");
  else if(state == -1) return translate.instant("REVOKE");
  else if(state == 1) return translate.instant("RETURN");
}
export function formatToShareType(type, translate){
  if (type.indexOf('share') != -1) return translate.instant("NORMAL_SHARING");
  else if (type.indexOf('partner') != -1) return translate.instant("CONTINUOUS_SHARING");
}
export function formatShareConnectToString(type){
  if (type == 0) return 'Device Connected' ;
  else if (type == 1) return 'Device Disconnected';
}
export function formatToShareContent(content, translate){
  if (content.indexOf('insert') != -1) {
    if (content.indexOf('partner') != -1) return translate.instant("CREATE_CONTINUOUS_SHARING");
    else return translate.instant("CREATE_NORMAL_SHARING");
  } 
  else if (content.indexOf('delete') != -1) {
    if (content.indexOf('partner') != -1) return translate.instant("DELETE_CONTINUOUS_SHARING");
    else return translate.instant("DELETE_NORMAL_SHARING");
  } 
}
export function formatToPushType(type){
  if (type == 0) return 'Android' ;
  else if (type == 1) return 'iOS';
  else return "N/A"
}
export function formatToStateOnOff(state, translate){
  if (state == 0) return translate.instant("STATE.OFF");
  else if (state == 1) return translate.instant("STATE.ON");
  else return "N/A"
}

export function formatToButtonOnOff(index, value, translate){
  // value의 index번째 비트를 추출
  const bitAtGivenIndex = (value >> index) & 1;

  if (bitAtGivenIndex == 0) return translate.instant("STATE.OFF");
  else return translate.instant("STATE.ON");
}

export function isEnabledButton(index, value){
  // value의 index번째 비트를 추출
  const bitAtGivenIndex = (value >> index) & 1;
  return bitAtGivenIndex != 0;
}

export function nullCheck(checkedObj){
  for(let i = 0; i < checkedObj.length; i++){
  }
}
export function regEexCheck(checkValue: any, checkType: string){
  let Regex: any;
  if( checkType == 'number'){
    Regex = /^[0-9]*$/;
  }
  else if( checkType == 'float'){
    Regex = /^[0-9]+(.[0-9]+)?$/;
  }
  else if( checkType == 'carNum' ){
    return (/^[0-9]{2}[\s]*[가-힣]{1}[\s]*[0-9]{4}/gi.test(checkValue) || /^[가-힣]{2}[\s]*[0-9]{2}[가-힣]{1}[\s]*[0-9]{4}/gi.test(checkValue) || /^[0-9]{3}[\s]*[가-힣]{1}[\s]*[0-9]{4}/gi.test(checkValue));
  }
  else if( checkType == 'mobilePhonNum'){
    // 한국번호
    //Regex = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
    // 한국, 베트남 번호
    Regex = /^(010-?[0-9]{4}-?[0-9]{4})|(09[0-9]-?[0-9]{3}-?[0-9]{4})|(03[0-9]-?[0-9]{3}-?[0-9]{4})|(05[0-9]-?[0-9]{3}-?[0-9]{4})|(07[0-9]-?[0-9]{3}-?[0-9]{4})|(08[0-9]-?[0-9]{3}-?[0-9]{4})$/;
  }
  else if( checkType == 'landLinePhon' ){
    Regex = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-(\d{3,4})-(\d{4})$/;
  }
  else if( checkType == 'email'){
    Regex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  }
  const isValidFormat = Regex.test(checkValue);

  if (isValidFormat) {
    if (checkType == 'mobilePhonNum') {
      //
      // 길이 체크
      // 숫자만 추출
      const numberOnly = checkValue.toString().replace(/[^\d]/g, ''); 
      return (numberOnly.length === 10 || numberOnly.length === 11);
    }
  }

  return isValidFormat;
}

export function isEmpty(value): boolean{
  if( value == "" || value == null || value == undefined || (value != null && typeof value == "object" && !Object.keys(value).length ) ) {
    return true
  } else {
    return false
  }
};

export function setEmpty(value): any{
  if( value == "" || value == null || value == undefined || (value != null && typeof value == "object" && !Object.keys(value).length ) ) {
    return '-'
  } else {
    return value;
  }
}

// TODO: 아래의 두 함수는 해당 파일의 목적(데이터 형식 변환)과 어울리지 않음
// 추 후 따로 파일을 분리할것.
export function ToggleAddForm(formName: string) {
  const addformdiv = document.getElementById(formName);
  if (addformdiv.style.display == 'none') addformdiv.style.display = 'block';
  else addformdiv.style.display = 'none';
}
export function addViewCount(view_count: number, addCount: number, getDataCb){
  view_count = view_count + addCount;
  getDataCb(view_count);
}