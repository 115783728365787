import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import * as Format from '../tools/services/format.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {
  
  private format = Format;

  private isSucc: number;

  private type;
  private msgObj = {      
    type: -1,
    msgValue: "",
    receiver: ""
  };

  private currentByte;

  constructor( private rest: RestService, private translate: TranslateService) { 
    this.isSucc = 0;
    this.type = 0;
    this.currentByte = 0;
  }

  ngOnInit() { }

  checkByte(){
    const stringByteLength = ( function(s,b,i,c) {
      for(b=i=0; c=s.charCodeAt(i++); b+=c >> 11?2 : c >> 7?2 : 1);
      return b;
    })(this.msgObj.msgValue);

    this.currentByte = stringByteLength;
  }
  
  send(){
    if( this.currentByte > 2000 ){
      alert(this.translate.instant("ALERT.ERROR.FAIL.TEXT_MESSAGE.MAX_SIZE"));
      return;
    }

    const sendNums = this.msgObj.receiver.split('\n');
    const sendNumArray = [];
    const smsBody = {
      receiver: sendNums,
      contents: this.msgObj.msgValue,
      ismulti: false,
      type: 1
    };

    console.log(smsBody.ismulti);
    if(  this.format.isEmpty( smsBody.contents ) ){ 
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.TEXT_MESSAGE"));
      return
    } else if (  this.format.isEmpty( smsBody.receiver ) ) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.RECEIVER_PHONE_NUMBER"));
      return
    } else if( smsBody.receiver.length > 1 ) {
      smsBody.ismulti = true;
    }

    const SNLength = sendNums.length;
    if( SNLength  > 1000 ) {
      for( let i = 0; i < Math.ceil( SNLength / 1000 ); i++) {
        let end = (i + 1) * 1000;

        if( end > SNLength )
          end = SNLength;
        
        sendNumArray[i] = sendNums.slice( ( i * 1000 ), end );
      }
      
      const SNALength = sendNumArray.length;
      for(let i = 0; i < SNALength; i++) {
        smsBody.receiver = sendNumArray[i];
        this.postMsg(smsBody);
      }
    } else {
        smsBody.receiver = sendNums;
        this.postMsg(smsBody);
    }
  }

  postMsg(smsBody){
      this.rest.postSms(smsBody).subscribe(
      res => {
        console.log("res =", res);
        if( res["code"] == 0 ){
          if( res["message"] ){
            alert(this.translate.instant("ALERT.ERROR.FAIL.TEXT_MESSAGE.SEND") + "\n: " + res["message"]["code"] + " " +res["message"]["message"]);
            this.isSucc = -1;
            return;
          }
          else{
            alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
            this.isSucc = 1;
            this.msgObj = {
              type: -1,
              msgValue: "",
              receiver: ""
            };
            this.checkByte();
          }
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.TEXT_MESSAGE.SEND") + "\n: " + res["message"]["code"] + " " +res["message"]["message"]);
          this.isSucc = -1;
          if ( res["code"] == -99005001 ) {
            // 팝빌 충전 url 보내는 메소드
            // this.getPopbillChargeUrl();
          }
        }
      },
      error => console.log(error),
      () => console.log("postSms() complate!!")
    );
  }
  getPopbillChargeUrl(){
    this.rest.getPopbillChargeUrl().subscribe(
      res => console.log(res)
    );
  }
  getGeneralUserPhonList(userType){
    this.msgObj.receiver = "";

    if(userType === '-1')
      return;

    this.rest.getGeneralUserPhoneList(userType).subscribe(
      res => {
        if (res["success"]) {
          res["user_list"].forEach(element => {
            this.msgObj.receiver += element.user_id + "\n";
          });
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.LIST"));
      });
  }
}
