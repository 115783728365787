export const BRAND_TYPE_KEYPLUS = "keyplus";
export const BRAND_TYPE_MANDO = "mando";
export const BRAND_TYPE_VALUE_MANDO = "M";

export function brandStrToKor(brand, translate){
  if(brand == BRAND_TYPE_KEYPLUS) return translate.instant("KEYPLUS");
  else if(brand == BRAND_TYPE_MANDO) return translate.instant("MANDO");
}

export function brandValueToStr(value){
    if(value == null) return BRAND_TYPE_KEYPLUS;
    else if(value == BRAND_TYPE_VALUE_MANDO) return BRAND_TYPE_MANDO;
    else return false;
}

export function getUserIdWithoutBrandValue(id){
  if(isMandoId(id)){
    return id.substr(1);
  } else {
    return id;
  }
}

export function makeMandoId(id){
  if (isMandoId(id)) {
    return id;
  } else {
    return this.BRAND_TYPE_VALUE_MANDO + id;
  }
}

function isMandoId(id){
  return id.charAt(0) == BRAND_TYPE_VALUE_MANDO;
}