import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../../app/tools/services/rest.service';
import * as Format from '../tools/services/format.service';
import * as BrandCvt from '../tools/services/brandConverter.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-carsearch',
  templateUrl: './carsearch.component.html',
  styleUrls: ['./carsearch.component.css']
})
export class CarsearchComponent implements OnInit {
  private format = Format;
  private brandCvt = BrandCvt;

  private searchText: string;

  private carsearchs: any[];
  private carsearchsFilteredCount = { count : 0 };

  private viewCount: number;

  constructor( private router:Router, private rest: RestService, private translate: TranslateService ) { 
    this.viewCount = 10;
  }

  ngOnInit() {
    this.getCarSearchList();
  }
  enter(event: KeyboardEvent, searchvalue){
    if (event.keyCode == 13){
      this.setSearchText(searchvalue);
    }
  }
  setSearchText( val ){
    this.searchText = val;
    this.viewCount = 10;
  }

  getCarSearchList(){
    this.rest.getCarSearchList().subscribe(
      res => {
        if (res["success"]) {
          this.carsearchs = res['carsearch'];
          console.log( "this.carsearchs = ", this.carsearchs );
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.LIST"));
      });
  }
  
  addViewCount(){
    this.format.addViewCount( this.viewCount, 10, ( view_count ) => {
      this.viewCount = view_count;
      this.getCarSearchList();
    });
  }
}
