import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { AuthService } from './tools/services/auth.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
import * as lsm from './tools/services/localstorageManager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'Keyplus-Admin-Web';
  constructor(private auth: AuthService, private router: Router, private translate: TranslateService) {
    // 대만어의 경우 'zh-TW' 이기 때문에 getBrowserCultureLang()를 사용해야될 수 있음
    const lang = this.translate.getBrowserLang();
    const localLang = lsm.getValueLang();
    if (localLang) {
      this.translate.setDefaultLang(localLang);
      this.translate.use(localLang);
    }else {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
    }

    this.auth.admin = lsm.getValueCurrentUser();
    this.auth.authToken = lsm.getValueToken();
    // TODO : 새로 빌드할 때, isCurrentUser가 true이지만 실제 데이터가 없는 것으로 보여짐
    if(this.auth.authToken)
      this.auth.isCurrentUser = true;
  }

  ngOnInit(): void { }
  ngAfterViewChecked() { Feather.replace(); }
  getBodyClass() {
    const _url = this.router.url;
    if (_url == '/login') {
      return 'bg-primary';
    }
    else if(_url == '/err'){
      return 'error-page';
    }
    else {
      return 'nav-fixed';
    }
  }
  changeLanguage(lang: string): void {
    lsm.setValueLang(lang);
    this.translate.use(lang);
  }
}
