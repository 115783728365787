import { Component, OnInit } from '@angular/core';
import { RestService } from '../../tools/services/rest.service';
import { AuthService } from '../../tools/services/auth.service';
import * as Format from '../../tools/services/format.service'
import * as Lsm from '../../tools/services/localstorageManager.service'
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {

  private searchText: string;
  private orderColumn: string;
  private isDesc: boolean;

  private pageObj: any;

  private notices: any[];
  private noticesFilteredCount = { count: 0 };
  private format = Format;
  private lsm = Lsm;

  constructor(private rest: RestService, private auth: AuthService, private translate: TranslateService) {
    this.pageObj = {
      view_count: 10,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1
    }
  }

  ngOnInit() {
    this.orderColumn = 'created';
    this.isDesc = true;
    this.getNotices();
  }
  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
      this.renewalPage();
    }
  }
  setSearchText(val) {
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
    this.searchText = val;
  }
  renewalPage() {
    this.pageSettingPageData();
    this.pageBtnActivateYn();
    this.pageMoveBlockBtnDisabledYn();
  }
  setOrder(column) {
    if (this.orderColumn == column) this.isDesc = !this.isDesc;
    else {
      this.isDesc = false;
      this.orderColumn = column
    };
  }
  pageSettingPageData() {
    this.pageObj.totalpage = Math.ceil(this.noticesFilteredCount.count / this.pageObj.view_count);
    if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
    this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

    if (this.pageObj.currentblock > this.pageObj.totalblock) this.pageObj.currentblock = this.pageObj.totalblock;
    if (this.pageObj.currentpage > this.pageObj.totalpage) {
      this.pageObj.currentpage = this.pageObj.totalpage;
      this.pageMovePage(this.pageObj.totalpage % 5);
    }
    this.pageMoveBlockBtnDisabledYn()
  }
  pageGetPage() {
    this.renewalPage();
    if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
    let value = Math.floor(this.pageObj.totalpage - ((this.pageObj.currentblock - 1) * 5));
    if (value == 0) value = 5;

    return value;
  }
  pageMoveBlock(type) {
    if (type == 1) {
      this.pageObj.currentblock--;
    }
    else if (type == 2) {
      this.pageObj.currentblock++;
    }
    this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + 1;
    this.renewalPage();
  }
  pageMovePage(pagenum) {
    this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + pagenum;
    this.renewalPage();
  }

  pageBtnActivateYn() {
    let pastId;
    let currId;

    let currentIndex = this.pageObj.currentpage % 5;
    if (currentIndex == 0) currentIndex = 5;
    currId = "page" + currentIndex;

    for (let i = 1; i <= 5; i++) {
      pastId = "page" + i;
      document.getElementById(pastId).classList.remove('btn-dark');
      document.getElementById(pastId).classList.add('btn-outline-dark');
    }
    document.getElementById(currId).classList.add('btn-dark');
    document.getElementById(currId).classList.remove('btn-outline-dark');
  }
  pageMoveBlockBtnDisabledYn() {
    if (this.pageObj.currentblock >= this.pageObj.totalblock) { document.getElementById("page_next").classList.add('disabled'); }
    else { document.getElementById("page_next").classList.remove('disabled'); }

    if (this.pageObj.currentblock == 1) { document.getElementById("page_prev").classList.add('disabled'); }
    else { document.getElementById("page_prev").classList.remove('disabled'); }
  }

  getNotices() {
    this.rest.getNotices().subscribe(
      res => {
        if (res["success"]) {
          this.notices = res['notcies'];
          console.log("this.notices = ", this.notices);
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.NOTICE_LIST"));
      }
    );
  }
  createNotice(noticeForm: NgForm) {
    const noticeObj = {
      // TODO: DB상에선 해당 공지사항을 작성한 관리자의 seq를 저장해놓은 
      // Colum이 있었음 허나 더미데이터의 admin_seq은 모두 null 임으로 일단 주석처리 해둠
      // admin_seq: this.auth.admin.id,
      title: noticeForm.value.title,
      content: noticeForm.value.content,
      is_view: 1,
      brand_type: this.lsm.getCurrentUserBrandByBrandValue()
    }
    if (this.format.isEmpty(noticeObj.title)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.SUBJECT"));
      return;
    } else if (this.format.isEmpty(noticeObj.content)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CONTENTS"));
      return;
    }
    this.rest.createNotice(noticeObj).subscribe(
      res => {
        console.log("res =", res);
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          noticeForm.reset();
          this.pageObj.currentblock = 1;
          this.pageMovePage(1);
          this.getNotices();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.COMMON"));
      });
  }
  deleteNotice(noticeSeq) {
    const result = confirm(this.translate.instant("ALERT.CONFIRM.DELETE.BOARD_ITEM"));
    if (!result) return;
    this.rest.deleteNotice(noticeSeq).subscribe(
      res => {
        console.log("res =", res);
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          this.pageObj.currentblock = 1;
          this.pageMovePage(1);
          this.getNotices();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.COMMON"));
      });
  }
}
