import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import { AuthService } from '../tools/services/auth.service';
import * as Format from '../tools/services/format.service';
import * as Lsm from '../tools/services/localstorageManager.service';
import * as BrandCvt from '../tools/services/brandConverter.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-as',
  templateUrl: './as.component.html',
  styleUrls: ['./as.component.css']
})
export class AsComponent implements OnInit {
  private format = Format;
  private lsm = Lsm;
  private brandCvt = BrandCvt;

  private searchText: string;
  private viewCount: number;
  private logs: any;
  private logsCount: number;
  private asLogTypes: any;
  private asLogtype = '';

  private logsFilteredCount = { count: 0 };

  constructor(private rest: RestService, private auth: AuthService, private translate: TranslateService) {
    this.searchText = '_';
    this.viewCount = 10;
  }

  ngOnInit() {
    this.getAsLogTypeList();
    this.getAsLogList();
  }
  enter(event: KeyboardEvent, searchvalue){
    if (event.keyCode == 13){
      this.setSearchText(searchvalue);
      this.getAsLogList();
    }
  }
  setSearchText( val ){
    this.searchText = this.convertLogQueryParams(val);
    this.viewCount = 10;
    this.getAsLogList();
  }
  getAsLogList() {
    console.log(this.searchText);
    this.rest.getAsLogList(this.searchText, this.viewCount).subscribe(
      res => {
        if (res["success"]) {
        this.logs = res["logs"]["rows"];
        this.logsCount = res["logs"]["count"];
        this.logsFilteredCount.count = this.logsCount;
        console.log("asLogList res = ", res);
      } 
      else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.AS_LOG_LIST"));
      });
  }
  createAsLog(asForm: NgForm) {
    if (!asForm.value.bracket)
      asForm.value.bracket = "";
    const asObj = {
      user_id: this.auth.admin.id,
      user_name: this.auth.admin.name,
      user_tel: this.auth.admin.tel,
      company_sn: this.auth.admin.company_sn,
      customer_tel: asForm.value.customer_tel,
      customer_name: asForm.value.customer_name,
      car_number: asForm.value.car_number,
      content: asForm.value.bracket + " " + asForm.value.content,
      as_type_seq: asForm.value.as_type,
      brand_type: this.lsm.getCurrentUserBrandByBrandValue()
    }
    console.log("asObj = ", asObj);
    if (this.format.isEmpty(asObj.customer_tel)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_PHONE_NUMBER"));
      return;
    } else if (this.format.isEmpty(asObj.customer_name)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_NAME"));
      return;
    } else if (this.format.isEmpty(asObj.car_number)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_CAR_NUMBER"));
      return;
    } else if (this.format.isEmpty(asObj.as_type_seq)) {
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.AS_TYPE"));
      return;
    } else if ( asObj.as_type_seq == 1 && asForm.value.bracket == "") {
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.BRACKAT_TYPE"));
      return
    } else if (this.format.isEmpty(asObj.content)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.AS_CONTENT"));
      return;
    } else if (!this.format.regEexCheck(asObj.car_number, 'carNum')) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CUSTOMER_CORRECTED_CAR_NUMBER"));
      return;
    }

    let AstypeTostring;
    this.asLogTypes.forEach(element => {
      if( element.seq == asObj.as_type_seq ) {
        AstypeTostring = element.name;
        console.log(AstypeTostring);
        return
      }
    });
    
    const result = confirm(this.translate.instant("ALERT.CONFIRM.ADD.AS_LOG")
    + "\n\n" + this.translate.instant("ALERT.CONFIRM.ADD.AS_LOG") + ": " + this.format.setDataFormat(asObj.customer_tel,'tel')
    + "\n" + this.translate.instant("CUSTOMER_NAME") + ": " + asObj.customer_name
    + "\n" + this.translate.instant("CUSTOMER_CAR_NUMBER") + ": " + asObj.car_number
    + "\n" + this.translate.instant("AS") + " " + this.translate.instant("TYPE") + ": " + AstypeTostring
    + "\n" + this.translate.instant("AS") + " " + this.translate.instant("CONTENTS") + ": " + asObj.content
    );
    if( !result ){
      return;
    }

    this.rest.createAsLog(asObj).subscribe(
      res => {
        console.log("res =", res);
        if (res["success"]) {
          alert("AS Log 작성 완료");
          asForm.reset();
          let element = <HTMLSelectElement>document.getElementById('asType');
          element.value = '';
          this.getAsLogList();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.AS_LOG"));
      });
  }
  getAsLogTypeList() {
    this.rest.getAsLogTypeList().subscribe(
      res => {
        if (res["success"]) {
        this.asLogTypes = res["as_type_list"];
        console.log("logTypes res = ", this.asLogTypes);
      }
      else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.AS_LOG_TYPE"));
      });
  }
  addViewCount() {
    this.format.addViewCount(this.viewCount, 10, (view_count) => {
      this.viewCount = view_count;
      this.getAsLogList();
    });
  }
  convertLogQueryParams( val ) {
    if ( this.format.isEmpty(val) ) return '_';
    else return val;
  }
}
