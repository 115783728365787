import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrComponent } from "./err/err.component";
import { LoginComponent } from "./login/login.component";
import { UserListComponent } from "./user/user-list.component";
import { UserDetailComponent } from "./user/user-detail.component";
import { CountryUserListComponent } from "./user/country-user-list.component";
import { SaleComponent } from "./sale/sale.component";
import { AsComponent } from "./as/as.component";
import { AdminListComponent } from "./admin/admin-list.component";
import { AdminDetailComponent } from "./admin/admin-detail.component";
import { CompanyListComponent } from "./company/company-list.component";
import { CompanyDetailComponent } from "./company/company-detail.component";
import { AppversComponent } from "./appvers/appvers.component";
import { NoticeComponent } from "./board/notice/notice.component";
import { NoticeDetailComponent } from "./board/notice/notice-detail.component";
import { FaqComponent } from "./board/faq/faq.component";
import { UserDetailCarComponent } from "./user/user-detail-car.component";
import { CarmakerListComponent } from "./carmaker/carmaker-list.component";
import { CarmakerDetailComponent } from "./carmaker/carmaker-detail.component";
import { CarsharesComponent } from "./carshares/carshares.component";
import { FwversComponent } from "./fwvers/fwvers.component";
import { CarsearchComponent } from "./carsearch/carsearch.component";
import { KeyworkComponent } from "./keywork/keywork.component";
import { SmsComponent } from "./sms/sms.component";
import { CompanyCodeListComponent } from "./company/company-code-list.component";
import { DeviceListComponent } from "./device/device-list.component";
import { DeviceDetailComponent } from "./device/device-detail.component";
import { MyInfoComponent } from "./myinfo/myinfo.component";
import { LoginRedirect } from "./tools/services/login-redirect.service";
import { EnsureAuthSuper } from "./tools/services/ensure-auth-super.service";
import { EnsureAuthBranch } from "./tools/services/ensure-auth-branch.service";
import { EnsureAuthAgency } from "./tools/services/ensure-auth-agency.service";
import { EnsureAuthenticated } from "./tools/services/ensure-authenticated.service";
import { EnsureBrandKeyplus } from "./tools/services/ensure-brand-keyplus.service";
import { EnsureAuthCompanySponeMando } from "./tools/services/ensure-auth-company-spone-mando.service";
import { EnsureAuthCompany } from "./tools/services/ensure-auth-company.service";
import { EnsureAuthCompanySpone } from "./tools/services/ensure-auth-company-spone.service";


const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "err", component: ErrComponent },
  { path: "login", component: LoginComponent, canActivate: [LoginRedirect] },
  {
    path: "users",
    component: UserListComponent,
    canActivate: [EnsureAuthenticated, EnsureAuthCompanySponeMando],
  },
  {
    path: "users/country",
    component: CountryUserListComponent,
    canActivate: [EnsureAuthenticated, EnsureBrandKeyplus],
  },
  {
    path: "user/:user_seq/:user_id",
    component: UserDetailComponent,
    canActivate: [EnsureAuthenticated],
  },
  // {
  //   path: "car/:car_seq",
  //   component: UserDetailCarComponent,
  //   canActivate: [EnsureAuthSuper],
  // },
  // {
  //   path: "sale",
  //   component: SaleComponent,
  //   canActivate: [EnsureAuthenticated, EnsureBrandKeyplus],
  // },
  // { 
  //   path: "as", 
  //   component: AsComponent, 
  //   canActivate: [EnsureAuthenticated] 
  // },
  {
    path: "admins",
    component: AdminListComponent,
    canActivate: [EnsureAuthBranch, EnsureAuthCompanySponeMando],
  },
  {
    path: "admin/:admin_id",
    component: AdminDetailComponent,
    canActivate: [EnsureAuthBranch],
  },
  {
    path: "devices",
    component: DeviceListComponent,
    canActivate: [EnsureAuthBranch, EnsureBrandKeyplus],
  },
  // {
  //   path: "devices/:device_seq",
  //   component: DeviceDetailComponent,
  //   canActivate: [EnsureAuthBranch, EnsureBrandKeyplus],
  // },
  {
    path: "companies",
    component: CompanyListComponent,
    canActivate: [EnsureAuthBranch, EnsureBrandKeyplus, EnsureAuthCompanySpone],
  },
  {
    path: "company/:company_sn",
    component: CompanyDetailComponent,
    canActivate: [EnsureAuthBranch, EnsureAuthCompany],
  },
  {
    path: "company_codes",
    component: CompanyCodeListComponent,
    canActivate: [EnsureAuthBranch, EnsureBrandKeyplus, EnsureAuthCompanySpone],
  },
  // {
  //   path: "appvers",
  //   component: AppversComponent,
  //   canActivate: [EnsureAuthSuper],
  // },
  // {
  //   path: "notices",
  //   component: NoticeComponent,
  //   canActivate: [EnsureAuthSuper],
  // },
  // {
  //   path: "notice/:notice_seq",
  //   component: NoticeDetailComponent,
  //   canActivate: [EnsureAuthenticated],
  // },
  // { 
  //   path: "faqs", 
  //   component: FaqComponent, 
  //   canActivate: [EnsureAuthSuper] 
  // },
  // {
  //   path: "carmakers",
  //   component: CarmakerListComponent,
  //   canActivate: [EnsureAuthSuper],
  // },
  // {
  //   path: "carmaker/:carmaker_seq",
  //   component: CarmakerDetailComponent,
  //   canActivate: [EnsureAuthSuper],
  // },
  {
    path: "carshares",
    component: CarsharesComponent,
    canActivate: [EnsureAuthSuper, EnsureAuthCompanySponeMando],
  },
  // {
  //   path: "fwvers",
  //   component: FwversComponent,
  //   canActivate: [EnsureAuthSuper],
  // },
  {
    path: "carsearch",
    component: CarsearchComponent,
    canActivate: [EnsureAuthenticated, EnsureAuthCompanySponeMando],
  },
  {
    path: "keywork",
    component: KeyworkComponent,
    canActivate: [EnsureAuthenticated],
  },
  // {
  //   path: "sms",
  //   component: SmsComponent,
  //   canActivate: [EnsureAuthSuper, EnsureBrandKeyplus],
  // },
  {
    path: "myinfo",
    component: MyInfoComponent,
    canActivate: [EnsureAuthenticated],
  },
  { path: "**", redirectTo: "/err", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
