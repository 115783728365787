import { Component, OnInit } from '@angular/core';
import { RestService } from '../../app/tools/services/rest.service';
import * as Format from '../tools/services/format.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-appvers',
  templateUrl: './appvers.component.html',
  styleUrls: ['./appvers.component.css']
})
export class AppversComponent implements OnInit {

  private searchText: string;
  private orderColumn: string;
  private isDesc: boolean;

  private pageObj: any;

  private appvers: any[];
  private appversFilteredCount = { count: 0 };

  private format = Format;

  constructor(private rest: RestService, private translate: TranslateService) {
    this.orderColumn = 'created';
    this.isDesc = true;

    this.pageObj = {
      view_count: 15,
      currentpage: 1,
      totalpage: 1,
      currentblock: 1,
      totalblock: 1
    }
  }
  ngOnInit() {
    this.getAppVers();
  }

  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
      this.renewalPage();
    }
  }
  setSearchText(val) {
    this.pageObj.currentblock = 1;
    this.pageObj.currentpage = 1;
    this.searchText = val;
  }
  renewalPage() {
    this.pageSettingPageData();
    this.pageBtnActivateYn();
    this.pageMoveBlockBtnDisabledYn();
  }
  setOrder(column) {
    if (this.orderColumn == column) this.isDesc = !this.isDesc;
    else {
      this.isDesc = false;
      this.orderColumn = column
    };
  }
  pageSettingPageData() {
    this.pageObj.totalpage = Math.ceil(this.appversFilteredCount.count / this.pageObj.view_count);
    if (this.pageObj.totalpage == 0) this.pageObj.totalpage = 1;
    this.pageObj.totalblock = Math.ceil(this.pageObj.totalpage / 5);

    if (this.pageObj.currentblock > this.pageObj.totalblock) this.pageObj.currentblock = this.pageObj.totalblock;
    if (this.pageObj.currentpage > this.pageObj.totalpage) {
      this.pageObj.currentpage = this.pageObj.totalpage;
      this.pageMovePage(this.pageObj.totalpage % 5);
    }
  }
  pageGetPage() {
    this.renewalPage();

    if (this.pageObj.currentblock == 1) return this.pageObj.totalpage;
    let value = Math.floor(this.pageObj.totalpage - ((this.pageObj.currentblock - 1) * 5));
    if (value == 0) value = 5;

    return value;
  }
  pageMoveBlock(rl) {
    if (rl == 1) this.pageObj.currentblock--;
    else if (rl == 2) this.pageObj.currentblock++;

    this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + 1;
    this.renewalPage();
  }
  pageMovePage(pagenum) {
    this.pageObj.currentpage = ((this.pageObj.currentblock - 1) * 5) + pagenum;
    this.renewalPage();
  }

  pageBtnActivateYn() {
    let currId;
    let pastId;

    let currentIndex = this.pageObj.currentpage % 5;
    if (currentIndex == 0) currentIndex = 5;
    currId = 'page' + currentIndex;

    for (let i = 1; i <= 5; i++) {
      pastId = 'page' + i;
      document.getElementById(pastId).classList.remove('btn-dark');
      document.getElementById(pastId).classList.add('btn-outline-dark');
    }
    document.getElementById(currId).classList.add('btn-dark');
    document.getElementById(currId).classList.remove('btn-outline-dark');
  }
  pageMoveBlockBtnDisabledYn() {
    if (this.pageObj.currentblock >= this.pageObj.totalblock) document.getElementById('page_next').classList.add('disabled');
    else document.getElementById("page_next").classList.remove('disabled');

    if (this.pageObj.currentblock == 1) document.getElementById("page_prev").classList.add('disabled');
    else document.getElementById("page_prev").classList.remove('disabled');
  }

  getAppVers() {
    this.rest.getAppVers().subscribe(
      res => {
        if (res["success"]) {
          this.appvers = res['app_vers'];
          this.appvers.forEach(e => {
            e.version_android = parseFloat(e.version_android);
            e.version_ios = parseFloat(e.version_ios);
          })
          console.log("this.appvers = ", this.appvers);
        } 
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.APP_VERSION"));
      });
  }

  convertVerToString(ver) {
    if (ver == 1) return this.translate.instant("ANDROID") + ' ' + this.translate.instant("VERSION");
    else if (ver == 2) return this.translate.instant("IOS") + ' ' + this.translate.instant("VERSION");
  }

  updateAppVer(appverForm: NgForm) {
    const appverObj = appverForm.value;

    if (this.format.isEmpty(appverObj.type)){
      alert(this.translate.instant("ALERT.VALIDATE.CHOICE.PLATFORM"));
      return;
    } else if (this.format.isEmpty(appverObj.version)) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.VERSION"));
      return;
    } else if (!this.format.regEexCheck(appverObj.version, 'float')) {
      alert(this.translate.instant("ALERT.VALIDATE.INPUT.CORRECT_VERSION"));
      return
    } else {
      if (confirm(this.translate.instant("ALERT.CONFIRM.ADD.APP_VERSION") + "\n\n" + 
        this.translate.instant("TYPE") +": " + this.convertVerToString(appverObj.type) +
        "\n" + this.translate.instant("VERSION") +": " + appverObj.version)) {
        this.rest.updateAppVer(appverObj.type, appverObj.version).subscribe(
          res => {
            console.log("res =", res);
            if (res["success"]) {
              alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
              appverForm.reset();
              let element = <HTMLSelectElement>document.getElementById('versionType');
              element.value = '';
              this.getAppVers();
            } 
            else alert(this.translate.instant("ALERT.ERROR.FAIL.ADD.APP_VERSION"));
          });
      }
    }
  }
  ToggleAddForm() {
    var addformdiv = document.getElementById("appVerAddForm");
    if (addformdiv.style.display == 'none') addformdiv.style.display = 'block';
    else addformdiv.style.display = 'none';
  }
}
