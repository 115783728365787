import { Component, OnInit } from '@angular/core';
import { RestService } from '../tools/services/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as Format from '../tools/services/format.service';
import { AuthService } from '../tools/services/auth.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-detail-car',
  templateUrl: './user-detail-car.component.html'
})
export class UserDetailCarComponent implements OnInit {

  private car: any;
  private carObj: any;

  private format = Format;

  constructor(private location: Location, private rest: RestService, private router: Router, private actRouter: ActivatedRoute, private auth: AuthService, private translate: TranslateService) {
    this.car = {
      seq: ""
    }
  }
  ngOnInit() {
    this.car.seq = this.actRouter.snapshot.params['car_seq'];

    console.log(this.location.getState());
    this.getCarByCarSeq();
  }
  backClicked() {
    this.location.back();
  }
  getCarByCarSeq() {
    this.rest.getCarByCarSeq(this.car.seq).subscribe(
      res => {
        if (res["success"]) {
          this.car = res["car"][0];
          console.log("car = ", this.car);
          this.carObj = Object.assign({}, this.car);
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
      });
  } 
  updateCar() {
    if (this.auth.admin.role_id < 4) {
      alert(this.translate.instant("ALERT.ERROR.HTTP.401"));
      return;
    }
    if (JSON.stringify(this.carObj) === JSON.stringify(this.car))
      return;
    const result = confirm(this.translate.instant("ALERT.CONFIRM.UPDATE.COMMON"));
    if (!result) return;
    this.rest.updateCar(this.carObj).subscribe(
      res => {
        console.log("res =", res);
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          this.getCarByCarSeq();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.UPDATE.COMMON"));
      });
  }
  deleteCar() {
    if (this.auth.admin.role_id < 4) {
      alert(this.translate.instant("ALERT.ERROR.HTTP.401"));
      return;
    }
    const result = confirm(this.translate.instant("ALERT.CONFIRM.DELETE.COMMON"));
    if (!result) return;
    this.rest.deleteCar(this.car.seq).subscribe(
      res => {
        if (res["success"]) {
          alert(this.translate.instant("ALERT.SUCCESS.COMMON"));
          console.log("this.car.user_seq = " + this.car.user_seq);
          window.history.back();
        }
        else alert(this.translate.instant("ALERT.ERROR.FAIL.DELETE.COMMON"));
      });
  }
}