import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RestService } from "../tools/services/rest.service";
import { AuthService } from "../tools/services/auth.service";
import * as Format from "../tools/services/format.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-device-list",
  templateUrl: "./device-list.component.html",
  styleUrls: ["./device-list.component.css"],
})
export class DeviceListComponent implements OnInit {
  private searchText: string;
  private deviceStateFiltering: number;
  private userCountryCodeFiltering: string;

  private deviceStateFilteringList  = [
    { id: 1, value: 1, name: "ALL" },
    { id: 2, value: 2, name: "USING" },
    { id: 3, value: 3, name: "WAITING" },
  ];

  private userCountryCodeFilteringList = [
    { id: 1, value: "ALL", name: "ALL" },
    { id: 2, value: "ETC", name: "ETC" },
  ];

  private format = Format;
  private devices: any;
  private devicesSrc: any;
  private devicesFilteredCount = { count: 0 };
  private loading: boolean;
  private countryCode: string;

  private viewCount: number;
  private deviceAmount = {
    total: 0,
    using: 0,
    waiting: 0,
    filtering: 0,
  };

  // private queryParamsUpdate = new Subject<void>(); // 쿼리 파라미터 업데이트를 위한 Subject

  constructor(
    private router: Router,
    private actRouter: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private rest: RestService,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    this.loading = true;
    this.viewCount = 10;
    //
    // 회사가 속한 나라 코드 넣기...
    if (this.auth.admin.admin_company && typeof this.auth.admin.admin_company.code === 'string') {
      this.userCountryCodeFilteringList.push(
        {
          id: 3,
          value: this.auth.admin.admin_company.code,
          name: "COUNTRY_ISO." + this.auth.admin.admin_company.code,
        }
      );

      this.userCountryCodeFiltering = this.auth.admin.admin_company.code;
      this.countryCode = this.auth.admin.admin_company.code;
    } else {
      this.userCountryCodeFiltering = "ALL";
      this.countryCode = '';
    }

    this.deviceStateFiltering = 2;

    // 디바운스 설정
    // this.queryParamsUpdate.pipe(debounceTime(300)).subscribe(() => {
    //   this.updateQueryParams();
    // });
  }

  ngOnInit() {
    // 쿼리 파라미터에서 값을 읽어옵니다
    const stateParam = this.actRouter.snapshot.queryParams['state'];
    const countryParam = this.actRouter.snapshot.queryParams['country'];

    // state 파라미터의 값이 유효한지 확인하고 기본값을 설정합니다
    let validParam = true;
    if (this.isValidState(stateParam)) {
      this.deviceStateFiltering = +stateParam;
    } else {
      validParam = false;
    }
    if (this.isValidCountryCode(countryParam)) {
      this.userCountryCodeFiltering = countryParam;
    } else {
      validParam = false;
    }

    this.getDeviceList();  
  }

  isValidState(value: any): boolean {
    return !isNaN(value) && [1, 2, 3].includes(+value); // 유효한 상태 값인지 확인
  }

  isValidCountryCode(value: any): boolean {
    return typeof value === 'string' && (['ALL', 'ETC'].includes(value) || this.countryCode == value);
  }

  enter(event: KeyboardEvent, searchvalue) {
    if (event.keyCode == 13) {
      this.setSearchText(searchvalue);
    }
  }
  setSearchText(val) {
    this.searchText = val;
    this.viewCount = 10;
  }
  getDeviceList() {
    this.loading = true;
    this.rest
      .getDeviceListByCompanyCode(this.auth.admin.admin_company.code)
      .subscribe((res) => {
        if (res["success"]) {
          const resultDevices = res["devices"];
          this.devicesSrc = resultDevices;
          this.changeDevices();
          if (Array.isArray(this.devices)) {
            const totalCount = resultDevices.length;
            const usingCount = resultDevices.filter(device => device.user_seq).length;
            this.deviceAmount.total = totalCount;
            this.deviceAmount.using = usingCount;
            this.deviceAmount.waiting = totalCount - usingCount;
          }
        } else {
          alert(this.translate.instant("ALERT.ERROR.FAIL.GET.COMMON"));
        }

        this.loading = false;
      });
  }
  addViewCount() {
    this.format.addViewCount(this.viewCount, 10, (view_count) => {
      this.viewCount = view_count;
      this.getDeviceList();

      if (this.viewCount >= this.devicesFilteredCount.count) {
        let viewmore = document.getElementById("viewmore");
        viewmore.style.display = "none";
      }
    });
  }

  changeDeviceStateFiltering() {
    this.changeDeviceFiltering();
  }
  changeUserCountryCodeFiltering() {
    this.changeDeviceFiltering();
  }
  changeDeviceFiltering() {
    if (this.loading) {
      // 보류
    } else {
      this.changeDevices();
    }
  }
  changeDevices() {
    if (Array.isArray(this.devicesSrc)) {
      if (this.devicesSrc.length > 0) {
        let filteredList = [];
        switch(this.deviceStateFiltering) {
          case 1:
            filteredList = [...this.devicesSrc];
            break;
          case 2:
            filteredList = this.devicesSrc.filter(i => typeof i.user_seq !== 'undefined');
            break;
          case 3:
            filteredList = this.devicesSrc.filter(i => typeof i.user_seq === 'undefined');
            break;
          default:
            filteredList = [...this.devicesSrc];
            break;
        }

        switch(this.userCountryCodeFiltering) {
          case "ALL":
            this.devices = [...filteredList]; // 새로운 배열로 설정
            break;
          case "ETC":
            this.devices = filteredList.filter(i => typeof i.country_code === 'string' &&  i.country_code != this.countryCode);
            break;
          default:
            this.devices = filteredList.filter(i => typeof i.country_code === 'string' &&  i.country_code == this.countryCode);
            break;
        }

        this.deviceAmount.filtering = this.devices.length;
        this.cdr.detectChanges();
      }
    }
  }
}